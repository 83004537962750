<template>
    <div class="container">
        <el-container>
            <el-aside>
                <el-dropdown @command="handleCommand"
                    style="width:98%;text-align: center;padding-top:20px;padding-bottom:20px;">
                    <span class="el-dropdown-link" style="width:100%;text-align: center;">
                        <el-icon>
                            <UserFilled />
                        </el-icon> 用户
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu style="text-align: center;">
                            <p style="color:#606266;font-weight: 700;margin-left:10px;margin-right:10px;">{{
                                currUser.name }}</p>
                            <el-dropdown-item command="logout"><i class="iconfont icon-dengchu"></i>登出</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <el-menu :router="true" class="el-menu-vertical-demo">
                    <el-menu-item index="/dropPoint">
                        <template #title>
                            <el-icon>
                                <LocationInformation />
                            </el-icon>
                            <span>投放点管理</span>
                        </template>
                    </el-menu-item>
                    <el-menu-item index="/device">
                        <template #title>
                            <el-icon>
                                <Box />
                            </el-icon>
                            <span>设备管理</span>
                        </template>
                    </el-menu-item>
                    <el-menu-item index="/invest">
                        <template #title>
                            <el-icon>
                                <MessageBox />
                            </el-icon>
                            <span>设备分配管理</span>
                        </template>
                    </el-menu-item>
                    <el-menu-item index="/customer">
                        <template #title>
                            <el-icon>
                                <Memo />
                            </el-icon>
                            <span>客户管理</span>
                        </template>
                    </el-menu-item>
                    <el-menu-item index="/appletUser">
                        <template #title>
                            <el-icon>
                                <Memo />
                            </el-icon>
                            <span>小程序用户管理</span>
                        </template>
                    </el-menu-item>
                    <el-menu-item index="/consult">
                        <template #title>
                            <el-icon>
                                <Message />
                            </el-icon>
                            <span>访问预约查询</span>
                        </template>
                    </el-menu-item>
                    <el-menu-item index="/news">
                        <template #title>
                            <el-icon>
                                <Tickets />
                            </el-icon>
                            <span>资讯管理</span>
                        </template>
                    </el-menu-item>
                    <el-menu-item index="/publicity">
                        <template #title>
                            <el-icon>
                                <Tickets />
                            </el-icon>
                            <span>小程序宣传页配置</span>
                        </template>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-container>
                <!-- <el-head><el-button @click="logout">logout</el-button></el-head> -->
                <el-main class="page_main" :style="asideStyle" dir="rtl">
                    <router-view dir="ltr"></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import { RouterView, useRouter } from 'vue-router';
var self;
export default {
    name: "HomeView",
    data() {
        return {
            noLookSysMessasgeNum: 0,
            currUser: {},
            menuTitle: '应用',
            asideStyle: {
                height: ''
            }
        };
    },
    setup() {
        const router = useRouter()
    },
    created() {

    },
    mounted() {
        self = this;
        // self.currUser = ALR.User.current()
        this.asideStyle.height = (document.documentElement.clientHeight || document.body.clientHeight) + 'px';
    },
    unmounted() {
    },
    methods: {
        handleCommand(command) {
            if (command === 'logout') {
                this.logout();
                this.$router.push({
                    path: '/login'
                })
            }
        },
        logout() {
            console.log(`登出`)
            self.CBSDK.User.logout()
        }
    }
}
</script>

<style lang="scss">
.page {
    background: #f2f3f4;
    background-size: 5px 5px;
}

.page_aside {
    background: #f2f3f4;
    background-size: 5px 5px;
    border-right: 1px solid #f2f3f4;
}

.page_main {
    background: #f2f3f4;
    background-size: 20px 20px;
    overflow: auto;
}

.page_main::-webkit-scrollbar {
    width: 2px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 2px;
}

.page_main::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
}

.page_main::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #EDEDED;
}

.iconfont {
    margin-left: 3px;
}

.el-menu-item i {
    color: #555;
}

.el-menu-item:hover i {
    color: #25252e;
}
</style>