<template>
    <div class="applet_user_view">
        <div class="head">
            <h1>小程序用户管理</h1>
        </div>
        <el-divider />
        <div class="body">
            <div class="search_panel">
                <el-input style="width: 500px;" v-model="searchContent" placeholder="可根据名称进行查询" clearable >
                    <template #prepend>
                        <el-icon>
                        <Search />
                    </el-icon>
                    </template>
                </el-input>
                <el-button @click="_searchData">搜索</el-button>
            </div>
            <el-table :data="appletUsers" v-loading="tableLoading">
                <el-table-column prop="type" label="类型">
                    <template #default="scope">
                        <el-tag :type="scope.row.type == 1 ? 'success' : scope.row.type == 2 ? 'warning' : 'info'">{{
                            scope.row.type == 1 ? '客户' : scope.row.type == 2 ? '员工' : '未设置' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="nick_name" label="微信昵称"></el-table-column>
                <el-table-column prop="gender" label="关联客户信息">
                    <template #default="scope">
                        <el-tag :type="scope.row.customer.length > 0 ? 'success' : 'info'">{{ scope.row.customer.length > 0 ? '已关联' : '未关联'
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="gender" label="性别">
                    <template #default="scope">
                        {{ scope.row.customer.length > 0 ? scope.row.customer[0].gender == '1' ? '男' : '女' : ""}}
                    </template>
                </el-table-column>
                <el-table-column prop="customer[0].name" label="姓名"></el-table-column>
                <el-table-column prop="customer[0].age" label="年龄"></el-table-column>
                <el-table-column prop="customer[0].id_card" label="身份证"></el-table-column>
                <el-table-column prop="customer[0].phone" label="联系电话"></el-table-column>
                <el-table-column label="操作" width="200" fixed="right">
                    <template #default="scope">
                        <el-button type="text" @click="_updateAppletUserType(scope.row)">设置为{{ scope.row.type ==
                            1 ? '工作人员' : '客户' }}</el-button>
                        <el-button type="text" @click="_connectCustomerInfo(scope.row)">关联客户信息</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-pagination v-model:current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next"
                :total="total" @current-change="handleCurrentChange" />
        </div>
        <el-dialog v-model="dialogFormVisible" title="管理客户信息">
            <el-form :model="customer" label-width="auto">
                <el-form-item label="搜索客户">
                    <el-select v-model="customer" filterable remote reserve-keyword value-key="_id" remote-show-suffix
                        placeholder="请输入客户姓名" :remote-method="remoteFindCustomer" :loading="loading"
                        @change="selectCustomerChange">
                        <el-option v-for="item in customerOptions" :key="item" :label="item.name + '(' + item.id_card + ')'"
                            :value="item" />
                    </el-select>
                    <el-button @click="resetCustomer">清除</el-button>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="customer.name" disabled style="width:216px;" />
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="customer.phone" disabled style="width:216px;" />
                </el-form-item>
                <el-form-item label="身份证">
                    <el-input v-model="customer.id_card" disabled style="width:216px;" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="_closeConnectCustomerInfo">取消</el-button>
                    <el-button type="primary" @click="_connect">
                        确认
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { getCurrentInstance, ref, onMounted, reactive } from 'vue'
import { useAppletUserStore } from '@/store/appletUserStore'
import { ElNotification, ElMessage, ElMessageBox, ElLoading } from 'element-plus'
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;
const router = useRouter()
const appletUsers = ref([])
const pageNum = ref(1)
const pageSize = ref(10)
const total = ref(0)
const dialogFormVisible = ref(false)
const tableLoading = ref(false)
const searchContent = ref('')

const _searchData = () => {
    
    pageNum.value = 1
    _loadAppletUsers()
}

const loading = ref(false)
const customerOptions = ref([])

var selectedId = null

var customer = ref({
    _id: '',
    name: '',
    phone: '',
    id_card: '',
    gender: 0,
    age: 0
})

const resetCustomer = () => {
    selectedId = null
    customer.value = {
        _id: '',
        name: '',
        phone: '',
        id_card: '',
        gender: 0,
        age: 0
    }
}

const remoteFindCustomer = (query) => {
    if (query) {
        CBSDK.Customer.find({
            name: query
        }).then(res => {
            loading.value = false
            customerOptions.value = res
        }).catch(err => {
            loading.value = false
        })
    }
}

const selectCustomerChange = (value) => {
    customer.value = value
}

const _loadAppletUsers = () => {
    tableLoading.value = true
    CBSDK.AppletUser.query({
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        searchContent: searchContent.value
    }).then(res => {
        
        appletUsers.value = res.data
        total.value = res.total
        tableLoading.value = false
    }).catch(err => {
        tableLoading.value = false
    })
}

const handleCurrentChange = (val) => {
    pageNum.value = val
    _loadAppletUsers()
}

const _connectCustomerInfo = (obj) => {
    resetCustomer()
    selectedId = obj._id
    if (obj.customer && obj.customer.length > 0) {
        remoteFindCustomer(obj.customer[0].name)
        customer.value = obj.customer[0]
    }
    dialogFormVisible.value = true
}

const _connect = () => {
    CBSDK.AppletUser.connectCustomerInfo({
        applet_user_id: selectedId,
        connect_type: 1,
        code: customer.value._id
    }).then(res => {
        if (res.code == 'success') {
            ElNotification({
                title: '操作成功',
                message: '已关联客户信息',
                type: 'success',
            })
            _closeConnectCustomerInfo()
            _loadAppletUsers()
        } else {
            ElNotification({
                title: '操作失败',
                message: '错误:' + res.message,
                type: 'error',
            })
        }

    }).catch(err => {
        ElNotification({
            title: '操作失败',
            message: '错误:' + err,
            type: 'error',
        })
    })
}

const _closeConnectCustomerInfo = () => {
    dialogFormVisible.value = false
    resetCustomer()
}

const _updateAppletUserType = (obj) => {
    ElMessageBox.confirm(
        '是否确认将[' + obj.nick_name + ']设置为' + (obj.type == 1 ? '工作人员?' : '客户?'),
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            CBSDK.AppletUser.updateAppletUserType({
                _id: obj._id,
                type: obj.type == 1 ? 2 : 1
            }).then(res => {
                if (res.code == 'success') {
                    ElNotification({
                        title: '操作成功',
                        message: '已修改为' + (obj.type == 1 ? '工作人员?' : '客户'),
                        type: 'success',
                    })
                    _loadAppletUsers()
                } else {
                    ElNotification({
                        title: '操作失败',
                        message: '错误:' + res.message,
                        type: 'error',
                    })
                }

            }).catch(err => {
                ElNotification({
                    title: '操作失败',
                    message: '错误:' + err,
                    type: 'error',
                })
            })
        })
        .catch(() => {
        })
}

onMounted(() => {
    _loadAppletUsers()
})

</script>

<style lang="scss" scoped>
.applet_user_view {
    width: 100%;

    .head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;
        .search_panel {
            margin: 10px 0;
            padding: 10px;
            display: flex;
            justify-content: start;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>