import CBSDK from "./cbsdk";

import User from './user'
import Common from './common'
import DropPoint from './dropPoint'
import Device from './device'
import InvestDevice from './investDevice'
import News from './news'
import Consult from './consult'
import Customer from './customer'
import AppletUser from './appletUser'
import Utils from './utils'
import Publicity from './publicity'

CBSDK.User = new User()
CBSDK.Common = new Common()
CBSDK.DropPoint = new DropPoint()
CBSDK.Device = new Device()
CBSDK.InvestDevice = new InvestDevice()
CBSDK.News = new News()
CBSDK.Consult = new Consult()
CBSDK.Utils = new Utils()
CBSDK.Customer = new Customer()
CBSDK.AppletUser = new AppletUser()
CBSDK.Publicity = new Publicity()
window.CBSDK = CBSDK;

export default CBSDK;