<template>
  <router-view/>
  <!-- <a style="position: absolute;margin-bottom:10px;">湘ICP备2023027506号</a> -->
  <div style="position: absolute;right:0;left:0;bottom:0px;width:100%;height:40px;align-items: center;text-align: center;">
    <div style="position: relative;margin-right: auto;margin-left:auto;border:1px solid #f3f4f5;border-radius:10px 10px 0 0;width:300px;height:30px;background-color:white;padding-top:10px;">
      <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#000000;">备案号:湘ICP备2023027506号</a>
    </div>
  </div>
</template>

<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
