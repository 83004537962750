import { defineStore } from 'pinia'

export const useAppletUserStore = defineStore({
    id: 'appletUser',
    state: () => ({
        appletUser: null
    }),
    actions: {
        set(appletUser) {
            this.appletUser = appletUser
        },
        get() {
            return this.appletUser
        },
        has() {
            return this.appletUser !== null && this.appletUser !== undefined && this.appletUser !== ''
        },
        clear() {
            this.appletUser = null
        }
    }
})