<template>
    <div class="home">
        <!-- 使用Element UI 编写登录框 -->
        <el-row>
            <el-col :span="12">
                <el-image class="logo" style="" :src="logoPath" :fit="fit" />
                <div class="title">小程序管理后台</div>
                <div><el-tag type="success" @click="openBAURL">湘ICP备2023027506号</el-tag></div>
            </el-col>
            <el-col :span="12" class="right_panel" :style="windowHeight">
                <div class="login_panel">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="用户名" prop="username">
                            <el-input v-model="ruleForm.username"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input type="password" v-model="ruleForm.password"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="success" plain @click="submitForm('ruleForm')">登录</el-button>
                            <el-button type="warning" plain @click="gotoSignUp()">内邀注册</el-button>
                        </el-form-item>
                    </el-form>
                    <!-- <span>
                        <p @click="gotoSignUp">邮箱注册</p>
                    </span> -->
                </div>

            </el-col>
        </el-row>
    </div>
</template>
  
<script>

var self;
export default {
    name: 'LoginView',
    data() {
        return {
            windowHeight: { height: '' },
            logoPath: '/jyhb-logo.jpg',
            ruleForm: {
                username: '',
                password: ''
            }
        }
    },
    components: {
    },
    mounted() {
        self = this;
        console.log(window.innerHeight)
        self.windowHeight.height = window.innerHeight + 'px';
        window.addEventListener("resize", this.myEventHandler);
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },

    methods: {
        myEventHandler(e) {
            // your code for handling resize...
            self.windowHeight.height = window.innerHeight + 'px';
        },
        submitForm(formName) {
            // 校验表单
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 表单验证通过
                    console.log('校验通过')
                    self.CBSDK.User.signInByEmail(self.ruleForm.username, self.ruleForm.password).then(res => {
                        console.log(res)
                        self.$router.push("/dropPoint")
                    }).catch(err => {
                        console.error(err)
                    })
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        gotoSignUp() {
            this.$router.push({ path: '/signUp' })
        },
        openBAURL() {
            window.open('https://beian.miit.gov.cn/', '_blank');
        }
    }
}
</script>
  
<style lang="scss">
.home {
    width: 100%;

    .title {
        color: #008d4a;
        font-size: 42px;
        font-weight: 800;
    }

    .right_panel {
        position: relative;
        background-color: #008d4a;
    }

    .login_panel {
        position: absolute;
        left: 0;
        right: 0;
        top: 320px;
        margin: auto;
        width: 450px;
        height: 300px;
        border-radius: 50px;
        background: #008d4a;
        box-shadow: 20px 20px 60px #00783f,
            -20px -20px 60px #00a255;
        padding: 10px;
    }

    .logo {
        margin-top: 300px;
        width: 300px;
        height: 300px
    }


    .el-form {
        margin-top: 80px;
        margin-right: 20px;
    }

    .el-form-item__label {
        color: #ffffff;
    }
}
</style>