<template>
    <div class="invest_device_view">
        <div class="head">
            <h1>设备分配管理</h1>
            <span class="import_btn">
                <el-button type="warning" @click="_importInvestDevice" text bg>
                    <el-icon>
                        <Edit />
                    </el-icon>
                    导入数据
                </el-button>
            </span>
            <span class="export_btn">
                <el-button type="danger" @click="_exportInvestDevice" text bg>
                    <el-icon>
                        <Edit />
                    </el-icon>
                    导出数据
                </el-button>
            </span>
            <span class="add_btn">
                <el-button type="success" @click="_addInvestDevice" text bg>
                    <el-icon>
                        <Edit />
                    </el-icon>
                    创建分配记录
                </el-button>
            </span>
        </div>
        <el-divider />
        <div class="body">
            <div class="search_panel">
                <el-input style="width: 500px;" v-model="searchContent" placeholder="可根据名称,设备名、编号、型号或投放点进行查询" clearable >
                    <template #prepend>
                        <el-icon>
                        <Search />
                    </el-icon>
                    </template>
                </el-input>
                <el-button @click="_searchData">搜索</el-button>
            </div>
            <el-table :data="investDevices" v-loading="tableLoading">
                <el-table-column prop="holder[0].name" label="持有人"></el-table-column>
                <!-- <el-table-column prop="holder.id_card" label="身份证"></el-table-column> -->
                <el-table-column prop="holder[0].phone" label="联系电话"></el-table-column>
                <el-table-column prop="device[0].code" label="设备编码"></el-table-column>
                <el-table-column prop="drop_point[0].name" label="投放单位"></el-table-column>
                <el-table-column prop="distributed_amount" label="配额(%)">
                    <template #default="scope">
                        <el-progress :text-inside="true" :stroke-width="22" :percentage="scope.row.distributed_amount" status="warning" />
                    </template>
                </el-table-column>
                <el-table-column prop="effective_duration" label="有效时长">
                    <template #default="scope">
                        {{ scope.row.effective_duration }} 月
                    </template>
                </el-table-column>
                <el-table-column prop="start_time" label="起始时间">
                    <template #default="scope">
                        {{ formatDate(scope.row.start_time) }}
                    </template>
                </el-table-column>
                <el-table-column prop="end_time" label="结束时间">
                    <template #default="scope">
                        {{ formatDate(scope.row.end_time) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200" fixed="right">
                    <template #default="scope">
                        <!-- <el-button type="text" @click="_editInvestDevice(scope.row)">编辑</el-button> -->
                        <el-button link type="warning" size="small" @click.prevent="_deleteInvestDevice(scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-pagination v-model:current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next"
                :total="total" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { getCurrentInstance, ref, onMounted } from 'vue'
import { useInvestDeviceStore } from '@/store/investDeviceStore'
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus'
import moment from 'moment';

const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;
const router = useRouter()
const investDevices = ref([])
const pageNum = ref(1)
const pageSize = ref(10)
const total = ref(0)
const searchContent = ref('')
const tableLoading = ref(false)
const _searchData = () => {
    
    pageNum.value = 1
    _loadInvestDevices()
}
const formatDate = (value) => {
    return moment(value).format('YYYY-MM-DD');
}

const _loadInvestDevices = () => {
    tableLoading.value = true
    CBSDK.InvestDevice.query({
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        searchContent: searchContent.value
    }).then(res => {
        investDevices.value = res.data
        
        total.value = res.total
        tableLoading.value = false
    }).catch(err => {
        tableLoading.value = false
    })
}

const handleCurrentChange = (val) => {
    pageNum.value = val
    _loadInvestDevices()
}

const _editInvestDevice = (obj) => {
    const investDeviceStore = useInvestDeviceStore()
    investDeviceStore.set(obj)
    router.push({ path: '/invest/edit' })
}

const _deleteInvestDevice = (obj) => {
    ElMessageBox.confirm(
        '确定删除['+obj.holder[0].name+'][' + obj.device[0].code + ']该设备分配记录吗?',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    ).then(res => {
        CBSDK.InvestDevice.removeInvestDevice(obj).then(res => {
            ElNotification({
                title: '操作成功',
                message: '设备分配记录已删除',
                type: 'success'
            })
            _loadInvestDevices()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: '删除设备分配记录失败:' + err,
                type: 'error'
            })
        })
    }).catch(err => {

    })
}

const _addInvestDevice = () => {
    const investDeviceStore = useInvestDeviceStore()
    investDeviceStore.clear()
    router.push({ path: '/invest/edit' })
}

const _exportInvestDevice = () => {
    const investDeviceStore = useInvestDeviceStore()
    investDeviceStore.clear()
    router.push({ path: '/invest/export' })
}

const _importInvestDevice = () => {
    const investDeviceStore = useInvestDeviceStore()
    investDeviceStore.clear()
    router.push({ path: '/invest/import' })
}

onMounted(() => {
    _loadInvestDevices()
})
</script>

<style lang="scss" scoped>
.invest_device_view {
    width: 100%;

    .head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }

        .import_btn {
            position: absolute;
            top: 0px;
            right: 260px;
        }

        .export_btn {
            position: absolute;
            top: 0px;
            right: 145px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;

        .search_panel {
            margin: 10px 0;
            padding: 10px;
            display: flex;
            justify-content: start;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>