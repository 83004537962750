import baseConfig from './config'
import cloudbase from "@cloudbase/js-sdk"

const common = class common {

    constructor() {
        this.app = cloudbase.init({ env: baseConfig.ENV, region: baseConfig.REGION })
        this.db = this.app.database()

    }

    uploadPublicityImage(cloudPath, filePath) {
        cloudPath = 'publicity/' + cloudPath
        return new Promise((resolve, reject) => {
            this.app.uploadFile({
                cloudPath: cloudPath,
                filePath: filePath
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    uploadNewsCover(cloudPath, filePath) {
        cloudPath = 'news/' + cloudPath
        return new Promise((resolve, reject) => {
            this.app.uploadFile({
                cloudPath: cloudPath,
                filePath: filePath
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    uploadDeviceImage(cloudPath, filePath) {
        cloudPath = 'device/' + cloudPath
        return new Promise((resolve, reject) => {
            this.app.uploadFile({
                cloudPath: cloudPath,
                filePath: filePath
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    uploadDropPointImage(cloudPath, filePath) {
        cloudPath = 'dropPoint/' + cloudPath
        return new Promise((resolve, reject) => {
            this.app.uploadFile({
                cloudPath: cloudPath,
                filePath: filePath
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    uploadFile(cloudPath, filePath) {
        cloudPath = 'other/' + cloudPath
        return new Promise((resolve, reject) => {
            this.app.uploadFile({
                cloudPath: cloudPath,
                filePath: filePath
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    deleteFile(cloudPath) {
        return new Promise((resolve, reject) => {
            this.app.deleteFile({
                fileList: [cloudPath]
            }).then((res) => {
                res.fileList.forEach((el) => {
                    if (el.code === "SUCCESS") {
                        //删除成功
                        resolve('删除成功')
                    } else {
                        reject('删除失败')
                    }
                })
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default common;