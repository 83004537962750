import base from './base'
import { isString, isNumber, isBoolean, isArray, isUndefined } from './dataType'
const publicity = class publicity extends base {

    constructor() {
        super()
        this.setDatabase('publicity')
    }

    query(options) {
        var params = {
            pageNum: 1,
            pageSize: 10,
        }

        if (!isUndefined(options.pageNum) && isNumber(options.pageNum)) {
            params.pageNum = options.pageNum;
        }
        if (!isUndefined(options.pageSize) && isNumber(options.pageSize)) {
            params.pageSize = options.pageSize;
        }
        if (!isUndefined(options.searchContent) && isString(options.searchContent))
        {
            params.searchContent = options.searchContent
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'queryPublicity', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }
    
    create(data) {
        var params = {
            methodName: "create"
        }
        if (!isUndefined(data)) {
            params.data = data
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'publicity', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, data) {
        var params = {
            methodName: "update"
        }
        if (!isUndefined(data)) {
            params.data = data
        }
        if(!isUndefined(id) && isString(id)) {
            params.id = id
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'publicity', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default publicity