import { defineStore } from 'pinia'

export const useNewsStore = defineStore({
    id: 'news',
    state: () => ({
        news: null
    }),
    actions: {
        set(news) {
            this.news = news
        },
        get() {
            return this.news
        },
        has() {
            return this.news !== null && this.news !== undefined && this.news !== ''
        },
        clear() {
            this.news = null
        }
    }
})