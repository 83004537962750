<template>
    <div class="device_edit_view">
        <div class="head">
            <el-affix position="top" :offset="0">
                <el-card shadow="never">
                    <el-page-header @back="goBack">
                        <template #content>
                            <span class="text-large font-600 mr-3"> {{ device._id ? '编辑' : '创建' }}设备
                            </span>
                        </template>
                        <template #extra>
                            <div class="flex items-center">
                                <el-button type="primary" class="ml-2" @click="saveDevice">保存</el-button>
                            </div>
                        </template>
                    </el-page-header>
                </el-card>
            </el-affix>
        </div>

        <div class="body">
            <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="18">
                    <el-card>
                        <el-form :model="device" label-position="top">
                            <el-form-item label="设备名称">
                                <el-input v-model="device.name" />
                            </el-form-item>
                            <el-form-item label="设备码">
                                <el-input v-model="device.code" />
                            </el-form-item>
                            <el-form-item label="设备型号">
                                <el-input v-model="device.model_number" />
                            </el-form-item>
                            <el-form-item label="设备规格">
                                <el-input v-model="device.specification" />
                            </el-form-item>
                            <el-form-item label="介绍">
                                <el-input v-model="device.content" type="textarea" :autosize="{ minRows: 4 }" />
                            </el-form-item>
                            <el-form-item>
                                <el-upload class="upload-demo" drag list-type="picture-card" v-model:file-list="fileList"
                                    action="#" multiple :limit="9" :http-request="uploadImage" :on-remove="removeImage"
                                    :before-upload="onUploadImageBefore" :on-progress="onUploadImageProgress"
                                    :on-success="onUploadImageSuccess">
                                    <el-icon>
                                        <Plus />
                                    </el-icon>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="投放点">
                                <el-select v-model="device.drop_point_id" filterable remote reserve-keyword value-key="_id"
                                    remote-show-suffix placeholder="请输入投放点" :remote-method="remoteFindDropPoint"
                                    :loading="loading">
                                    <el-option v-for="item in dropPointOptions" :key="item._id" :label="item.name"
                                        :value="item._id" />
                                </el-select>
                            </el-form-item>

                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="3"></el-col>
            </el-row>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router"
import { getCurrentInstance, onBeforeUnmount, ref, shallowRef, onMounted, reactive, h } from "vue";
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus'
import { useDeviceStore } from '@/store/deviceStore'
const router = useRouter()
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;



const dropPointOptions = ref([])
const loading = ref(false)
var device = reactive({
    name: '',
    code: '',
    model_number: '',
    specification: '',
    content: '',
    image_urls: [],
    status: 0,
    drop_point_id: null,
    distributable: true,
    distributed_amount: 0
})

const _resetDevice = () => {
    device = reactive({
        name: '',
        code: '',
        model_number: '',
        specification: '',
        content: '',
        image_urls: [],
        status: 0,
        drop_point_id: null,
        distributable: true,
        distributed_amount: 0
    })
}

var fileList = ref([])
const goBack = () => {
    router.go(-1)
}

const remoteFindDropPoint = (query) => {
    if (query) {
        loading.value = true
        findDropPointOptions(query)
    }
}

const findDropPointOptions = (query) => {
    CBSDK.DropPoint.find({
        name: query
    }).then(res => {
        loading.value = false
        dropPointOptions.value = res
        // console.log(dropPointOptions.value)
    }).catch(err => {
        loading.value = false
        // console.error(err)
    })
}


const saveDevice = () => {
    if (device.drop_point_id == null || device.drop_point_id == undefined || device.drop_point_id == '') {
        device.status = 0
    } else {
        device.status = 1
    }
    if (device._id == undefined || device._id == null || device._id == '') {
        CBSDK.Device.create(device).then(res => {
            ElNotification({
                title: '操作成功',
                message: h('i', { style: 'color: teal' }, '已添加投放点'),
                duration: 2000
            })
            goBack()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: h('i', { style: 'color: teal' }, '添加投放点错误:' + err),
                duration: 2000
            })
        })
    } else {
        const deviceObj = {
            name: device.name,
            code: device.code,
            model_number: device.model_number,
            specification: device.specification,
            content: device.content,
            image_urls: device.image_urls,
            status: device.status,
            drop_point_id: device.drop_point_id,
            distributable: device.distributable,
            distributed_amount: device.distributed_amount
        }
        CBSDK.Device.update(device._id, deviceObj).then(res => {
            ElNotification({
                title: '操作成功',
                message: h('i', { style: 'color: teal' }, '已修改投放点'),
                duration: 2000
            })
            goBack()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: h('i', { style: 'color: teal' }, '修改投放点错误:' + err),
                duration: 2000
            })
        })
    }
}

const setFileList = (url) => {
    fileList.value = updateTargetImage({ name: url.split('/').pop().split('?').shift(), url: url }, fileList.value)
}

const uploadImage = (obj) => {
    var filename = obj.file.name
    CBSDK.Common.uploadDeviceImage(filename, obj.file).then(res => {
        var images = device.image_urls
        images.push({ name: res.download_url.split('/').pop().split('?').shift(), url: res.download_url })
        setFileList(res.download_url)
        device.image_urls = images
    }).catch(err => {
        ElNotification({
            title: '图片上传失败',
            message: h('i', { style: 'color: teal' }, err),
            duration: 2000
        })
    })
}

const onUploadImageBefore = () => {
    // console.log('上传图片前')
}

const onUploadImageProgress = (evt, file, files) => {
    // console.log('上传图片中')
    // console.log(evt)
}

const onUploadImageSuccess = () => {
    // console.log('上传图片成功')
}

const removeTargetImage = (targetName, images) => {
    return images.filter(image => image.name !== targetName);
}


const updateTargetImage = (targetName, images) => {
    return images.map(image => {
        if (image.name === targetName.name) {
            // 这里是你想要修改的内容
            image = targetName;
        }
        return image;
    });
}

const removeImage = (uploadFile, uploadFiles) => {
    // console.log(uploadFile)
    // console.log(uploadFiles)
    device.image_urls = removeTargetImage(uploadFile.name, device.image_urls)
}

onMounted(() => {
    findDropPointOptions(null)
    const deviceStore = useDeviceStore()
    if (deviceStore.has()) {
        device = deviceStore.get()
        fileList.value = device.image_urls
        // console.log(fileList.value)
    } else {
        _resetDevice()
    }
})


</script>

<style scoped>
.device_edit_view {
    width: 100%;

    head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;

        .el-upload__text {
            width: 100%;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>