import { defineStore } from 'pinia'

export const useDropPointStore = defineStore({
    id: 'dropPoint',
    state: () => ({
        dropPoint: null
    }),
    actions: {
        set(dropPoint) {
            this.dropPoint = dropPoint
        },
        get() {
            return this.dropPoint
        },
        has() {
            return this.dropPoint !== null && this.dropPoint !== undefined && this.dropPoint !== ''
        },
        clear() {
            this.dropPoint = null
        }
    }
})