<template>
    <div class="publicity_view">
        <div class="head">
            <h1>小程序宣传页图集管理</h1>
            <span class="add_btn">
                <el-button type="success" @click="_addPublicity" text bg>
                    <el-icon>
                        <Edit/>
                    </el-icon>
                    新增
                </el-button>
            </span>
        </div>
        <el-divider/>
        <div class="body">
            <el-table :data="publicitys" v-loading="tableLoading" flexible>
                <el-table-column prop="weight" label="权重"></el-table-column>
                <el-table-column prop="image_url" label="图片">
                    <template #default="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.image_url" :fit="fit" />
                    </template>
                </el-table-column>

                <el-table-column label="操作" width="200" fixed="right">
                    <template #default="scope">
                        <el-button type="text" @click="_editPublicity(scope.row)">编辑</el-button>
                        <el-button link type="warning" size="small" @click.prevent="_deletePublicity(scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-pagination v-model:current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next" :total="total" @current-change="handleCurrentChange" />
        </div>
        <el-dialog v-model="dialogFormVisible" title="编辑">
            <el-form :model="publicity" label-width="auto">
                <el-form-item label="权重">
                    <el-input v-model="publicity.weight" type="number" style="width:216px;" />
                </el-form-item>
                <el-form-item label="封面">
                    <el-upload class="upload-demo" drag list-type="picture" v-model="publicity.image_url" action="#"
                        :http-request="uploadImage" v-model:file-list="fileList"><!-- :auto-upload="false"-->
                        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                        <div class="el-upload__text">
                            拖拽图片或 <em>点击上传</em>
                        </div>
                        <template #tip>
                            <div class="el-upload__tip">
                                仅支持图片格式，文件大小控制在500kb
                            </div>
                        </template>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="_closeDialogInfo">取消</el-button>
                    <el-button type="primary" @click="savePublicity">
                        确认
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { getCurrentInstance, ref, onMounted,reactive,h } from 'vue'
import { usePublicityStore } from '@/store/newsStore'
import { ElNotification, ElMessage, ElMessageBox, ElLoading } from 'element-plus'

const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;
const router = useRouter()
var publicity = ref({
    _id: '',
    image_url: '',
    weight: 0
})
var selectedId = null
const resetPublicity = () => {
    selectedId = null
    publicity.value = {
        _id: '',
        image_url: '',
        weight: 0
    }
    fileList.value = []
}

var fileList = reactive([])
const publicitys = ref([])
const pageNum = ref(1)
const pageSize = ref(10)
const total = ref(0)
const searchContent = ref('')
const tableLoading = ref(false)
const dialogFormVisible = ref(false)

const _loadPublicity = () => {
    tableLoading.value = true
    CBSDK.Publicity.query({
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        searchContent: searchContent.value
    }).then(res => {
        publicitys.value = res.data
        total.value = res.total
        tableLoading.value = false
    }).catch(err => {
        tableLoading.value = false
    })
}

const handleCurrentChange = (val) => {
    pageNum.value = val
    _loadPublicity()
}

const _editPublicity = (obj) => {
    publicity.value = obj
    setFileList(obj.image_url)
    dialogFormVisible.value = true
}

const _closeDialogInfo = () => {
    dialogFormVisible.value = false
    resetPublicity()
}

const _deletePublicity = (obj) => {
    ElMessageBox.confirm(
        '确定删除该图片吗?',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    ).then(res => {
        CBSDK.Publicity.remove(obj._id).then(res => {
            ElNotification({
                title: '操作成功',
                message: '图片已删除',
                type: 'success'
            })
            _loadPublicity()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: '删除图片失败:' + err,
                type: 'error'
            })
        })
    }).catch(err => {

    })
}

const _addPublicity = () => {
    resetPublicity()
    dialogFormVisible.value = true
}

const setFileList = (url) => {
    fileList = [{ name: url.split('/').pop().split('?').shift(), url: url }]
}

const uploadImage = (obj) => {
    const loading = ElLoading.service({
        lock: true,
        text: '数据查询中',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    var filename = obj.file.name
    CBSDK.Common.uploadPublicityImage(filename, obj.file).then(res => {
        publicity.value.image_url = res.download_url
        setFileList(res.download_url)
        loading.close()
    }).catch(err => {
        loading.close()
        ElNotification({
            title: '图片上传失败',
            message: h('i', { style: 'color: teal' }, err),
            duration: 2000
        })
    })
}

const savePublicity = () => {
    var publicityObj = publicity.value
    if (publicityObj._id == undefined || publicityObj._id == null || publicityObj._id == '') {
        const tempObj = {
            image_url: publicityObj.image_url,
            weight: publicityObj.weight
        }
        CBSDK.Publicity.create(tempObj).then(res => {
            ElNotification({
                title: '操作成功',
                message: h('i', { style: 'color: teal' }, '已添图片'),
                duration: 2000
            })
            dialogFormVisible.value = false
            _loadPublicity()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: h('i', { style: 'color: teal' }, '添加图片失败:' + err),
                duration: 2000
            })
        })
    } else {
        const tempObj = {
            image_url: publicityObj.image_url,
            weight: publicityObj.weight
        }
        CBSDK.Publicity.update(publicityObj._id, tempObj).then(res => {
            ElNotification({
                title: '操作成功',
                message: h('i', { style: 'color: teal' }, '已修改图片'),
                duration: 2000
            })
            dialogFormVisible.value = false
            _loadPublicity()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: h('i', { style: 'color: teal' }, '修改图片失败:' + err),
                duration: 2000
            })
        })
    }
}

onMounted(() => {
    _loadPublicity()
})
</script>
 
<style lang="scss" scoped>
.publicity_view {
    width: 100%;

    .head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;

        .search_panel {
            margin: 10px 0;
            padding: 10px;
            display: flex;
            justify-content: start;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>