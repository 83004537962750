<template>
    <div class="consult_view">
        <div class="head">
            <h1>咨询管理</h1>
            <!-- <span class="add_btn">
                <el-button type="success" @click="_addConsult" text bg>
                    <el-icon>
                        <Edit />
                    </el-icon>
                    新增
                </el-button>
            </span> -->
        </div>
        <el-divider />
        <div class="body">
            <el-table :data="consults" v-loading="tableLoading">
                <el-table-column prop="status" label="状态" width="80">
                    <template #default="scope">
                        <el-tag :type="scope.row.status ? 'success' : 'info'" size="small">{{ scope.row.status ? '已处理' :
                            '未处理' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="咨询人名称"></el-table-column>
                <el-table-column prop="phone" label="联系电话"></el-table-column>
                <el-table-column prop="create_time" label="创建时间">
                    <template #default="scope">
                        {{ formatDate(scope.row.create_time) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200" fixed="right">
                    <template #default="scope">
                        <el-button type="text" @click="_editConsult(scope.row)">标记为已处理</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-pagination v-model:current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next"
                :total="total" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { getCurrentInstance, ref, onMounted } from 'vue'
import { useConsultStore } from '@/store/consultStore'
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus'
import moment from 'moment';

const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;
const router = useRouter()
const consults = ref([])
const pageNum = ref(1)
const pageSize = ref(10)
const total = ref(0)
const tableLoading = ref(false)
const formatDate = (value) => {
    return moment(value).format('YYYY-MM-DD');
}

const _loadConsults = () => {
    tableLoading.value = true
    CBSDK.Consult.query({
        pageNum: pageNum.value,
        pageSize: pageSize.value
    }).then(res => {
        consults.value = res.data
        total.value = res.total
        tableLoading.value = false
    }).catch(err => {
        tableLoading.value = false
    })
}

const handleCurrentChange = (val) => {
    pageNum.value = val
    _loadConsults()
}

const _editConsult = (obj) => {
    // const consultStore = useConsultStore()
    // consultStore.set(obj)
    // router.push({ path: '/consult/edit'})

    ElMessageBox.confirm(
        '是否确认将[' + obj.name + ']的咨询标记为已处理?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    ).then(() => {
        console.log(obj)
        CBSDK.Consult.updateStatus(obj._id, {
            status: true
        }).then(res => {
            console.log(res)
            ElNotification({
                title: '操作成功',
                message: '标记为已处理',
                type: 'success',
            })
            _loadConsults()
        }).catch(err => {
            console.log(err)
            ElNotification({
                title: '操作失败',
                message: '错误:' + err,
                type: 'error',
            })
        })
    }).catch(() => {
    })
}

// const _deleteConsult = (obj) => {
// }

// const _addConsult = () => {
//     const consultStore = useConsultStore()
//     consultStore.clear()
//     router.push({ path: '/consult/edit'})
// }

onMounted(() => {
    _loadConsults()
})
</script>

<style lang="scss" scoped>
.consult_view {
    width: 100%;

    .head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .footer {
        text-align: center;
    }
}
</style>