<template>
    <div class="invest_device_export_view">
        <div class="head">
            <el-affix position="top" :offset="0">
                <el-card shadow="never">
                    <el-page-header @back="goBack">
                        <template #content>
                            <span class="text-large font-600 mr-3">
                                设备分配数据导出
                            </span>
                        </template>
                    </el-page-header>
                </el-card>
            </el-affix>
        </div>
        <div class="body">
            <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="18">
                    <el-card style="margin-top:10px;">
                        <div class="search_panel">
                            <el-input style="width: 500px;" v-model="searchContent" placeholder="可根据名称,设备名、编号、型号或投放点进行查询"
                                clearable>
                                <template #prepend>
                                    <el-icon>
                                        <Search />
                                    </el-icon>
                                </template>
                            </el-input>
                            <el-input type="number" v-model="pageNum" style="width: 240px;" placeholder="1" clearable>
                                <template #prepend>
                                    起始数
                                </template>
                            </el-input>
                            <el-input type="number" v-model="pageSize" style="width: 240px;" placeholder="100" clearable>
                                <template #prepend>
                                    结束数
                                </template>
                            </el-input>
                            <el-button type="success" @click="_loadInvestDevices">查询</el-button>
                        </div>
                        <el-row>
                            <el-col :span="6">
                                <el-statistic title="需执行批次" :value="batchNum" />
                            </el-col>
                            <el-col :span="6">
                                <el-statistic title="总数据条数" :value="total" />
                            </el-col>
                            <el-col :span="6">
                                <el-statistic title="查询数据条数" :value="investDevices.length" />
                            </el-col>
                        </el-row>

                        <el-button @click="exportToExcel" type="primary"
                            :disabled="investDevices.length == 0">导出</el-button>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router"
import { getCurrentInstance, ref, onMounted, reactive } from "vue";
import { ElNotification, ElLoading } from 'element-plus'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const router = useRouter()
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;

const investDevices = ref([])
const pageNum = ref(1)
const pageSize = ref(100)
const total = ref(0)
const searchContent = ref('')
const batchNum = ref(0)
const percentage = ref(0)

const _loadInvestDevices = () => {
    batchNum.value = calculateBatches(pageSize.value - (pageNum.value - 1))
    const loading = ElLoading.service({
        lock: true,
        text: '数据查询中',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    CBSDK.InvestDevice.query({
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        searchContent: searchContent.value
    }).then(res => {
        investDevices.value = res.data
        loading.close()
        total.value = res.total
    }).catch(err => {
        loading.close()
    })
}

const calculateBatches = (value) => {
    return Math.ceil(value / 100);
}

const exportToExcel = async () => {
    const loading = ElLoading.service({
        lock: true,
        text: '数据查询中',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    // 创建一个新的工作簿
    const wb = XLSX.utils.book_new();
    // 添加表头
    const ws_data = [
        ["客户设备信息匹配表", '', '', '', '', '', '', '', '', '', "客户设备信息匹配表"],
        ["公司名称：湖南XX公司", '', '', '', '', '', '', '', '', '', "公司名称：湖南XX公司"],
        ["序号", "客户信息", '', '', '', "设备信息", '', '', "租赁详情", '', ''],
        ["序号", "姓名", "联系电话", "身份证号码", "占比率", "设备名称", "设备型号", "设备编号", "租赁单位名称", "联系电话", "安装地点"]
    ];

    if (batchNum.value == 1) {
        // 将数据转换为工作表
        const temp_data = investDevices.value.map((item, index) => [
            item._id,
            item.holder[0].name || '',
            item.holder[0].phone || '',
            item.holder[0].id_card || '',
            item.distributed_amount || 0,
            item.device[0].name || '',
            item.device[0].model_number || '',
            item.device[0].code || '',
            item.drop_point[0].name || '',
            item.drop_point[0].phone || '',
            item.drop_point[0].address || ''
        ]);
        ws_data.push(...temp_data)
    } else {
        for (var i = 0; i < batchNum.value; i++) {
            if (i == 0) {
                const temp_data = investDevices.value.map((item, index) => [
                    item._id,
                    item.holder[0].name || '',
                    item.holder[0].phone || '',
                    item.holder[0].id_card || '',
                    item.distributed_amount || 0,
                    item.device[0].name || '',
                    item.device[0].model_number || '',
                    item.device[0].code || '',
                    item.drop_point[0].name || '',
                    item.drop_point[0].phone || '',
                    item.drop_point[0].address || ''
                ]);
                ws_data.push(...temp_data)
            } else {
                await CBSDK.InvestDevice.query({
                    pageNum: pageNum.value,
                    pageSize: pageSize.value,
                    searchContent: searchContent.value
                }).then(res => {
                    investDevices.value = res.data
                    const temp_data = investDevices.value.map((item, index) => [
                        item._id,
                        item.holder[0].name || '',
                        item.holder[0].phone || '',
                        item.holder[0].id_card || '',
                        item.distributed_amount || 0,
                        item.device[0].name || '',
                        item.device[0].model_number || '',
                        item.device[0].code || '',
                        item.drop_point[0].name || '',
                        item.drop_point[0].phone || '',
                        item.drop_point[0].address || ''
                    ]);
                    ws_data.push(...temp_data)
                }).catch(err => {
                    loading.close()
                })
            }
        }
    }

    // 定义样式
    const style = {
        font: {
            sz: 24,
            bold: true
        },
        border: {
            top: { style: 'thin', color: '000000' },
            bottom: { style: 'thin', color: '000000' },
            left: { style: 'thin', color: '000000' },
            right: { style: 'thin', color: '000000' }
        }
    };

    for(const key in ws_data) {
        if(ws_data[key] instanceof Object) {
            ws_data[key].s = style
        }
    }

    // 创建工作表
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    ws['!cols'] = [
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 200 }
    ]

    // 合并单元格
    ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, // 第1行A至J列
        { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } }, // 第2行A至J列
        { s: { r: 2, c: 0 }, e: { r: 3, c: 0 } }, // 第3,4行A列
        { s: { r: 2, c: 1 }, e: { r: 2, c: 4 } }, // 第3行B至D列
        { s: { r: 2, c: 5 }, e: { r: 2, c: 7 } }, // 第3行E至G列
        { s: { r: 2, c: 8 }, e: { r: 2, c: 10 } }, // 第3行H至J列
    ];

    // 将工作表添加到工作簿中
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // 将工作簿转换为二进制字符串
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // 将二进制字符串转换为 Blob 对象
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // 使用 FileSaver 保存文件
    saveAs(blob, 'export' + Date.now() + '.xlsx');
    loading.close()
}

// 将二进制字符串转换为 ArrayBuffer
const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}


const goBack = () => {
    router.go(-1)
}
</script>

<style lang="scss" scoped>
.invest_device_export_view {
    width: 100%;

    head {
        position: relative;

        h1 {
            text-align: left;
        }

    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 10px;

        .search_panel {
            margin: 10px 0;
            padding: 10px;
            display: flex;
            justify-content: start;
        }

        .el-statistic {
            --el-statistic-content-font-size: 28px;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>