import { defineStore } from 'pinia'

export const useConsultStore = defineStore({
    id: 'consult',
    state: () => ({
        consult: null
    }),
    actions: {
        set(consult) {
            this.consult = consult
        },
        get() {
            return this.consult
        },
        has() {
            return this.consult !== null && this.consult !== undefined && this.consult !== ''
        },
        clear() {
            this.consult = null
        }
    }
})