<template>
    <div class="news_edit_view">
        <div class="head">
            <el-affix position="top" :offset="0">
                <el-card shadow="never">
                    <el-page-header @back="goBack">
                        <template #content>
                            <span class="text-large font-600 mr-3"> {{ news._id ? '编辑' : '创建' }}资讯 " {{ news.title }} "
                            </span>
                        </template>
                        <template #extra>
                            <div class="flex items-center">
                                <el-button type="primary" class="ml-2" @click="saveNews">保存</el-button>
                            </div>
                        </template>
                    </el-page-header>
                </el-card>
            </el-affix>
        </div>
        <div class="body">
            <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="18">
                    <el-card>
                        <el-form :model="blog" label-position="top">
                            <el-form-item label="标题">
                                <el-input v-model="news.title" />
                            </el-form-item>
                            <el-form-item label="封面">
                                <el-radio-group v-model="setCoverType">
                                    <el-radio label="上传图片" />
                                    <el-radio label="URL" />
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="setCoverType == '上传图片'">
                                <el-upload class="upload-demo" drag list-type="picture" v-model="news.cover" action="#"
                                    :http-request="uploadCover" v-model:file-list="fileList"><!-- :auto-upload="false"-->
                                    <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                                    <div class="el-upload__text">
                                        拖拽图片或 <em>点击上传</em>
                                    </div>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            仅支持图片格式，文件大小控制在500kb
                                        </div>
                                    </template>
                                </el-upload>
                            </el-form-item>
                            <el-form-item v-if="setCoverType == 'URL'">
                                <el-input v-model="news.cover" />
                            </el-form-item>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="作者">
                                        <el-input v-model="news.author" style="width:220px" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="发布日期">
                                        <el-date-picker v-model="news.release_date" type="date" placeholder="Pick a day" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="源链接">
                                        <el-input v-model="news.link" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="状态">
                                        <el-switch v-model="news.status" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div style="border: 1px solid #ccc">
                                <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef"
                                    :defaultConfig="toolbarConfig" :mode="mode" />
                                <Editor style="height: 500px; overflow-y: hidden" v-model="news.content"
                                    :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
                            </div>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="3"></el-col>
            </el-row>

        </div>
    </div>
</template>
<script setup>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { getCurrentInstance, onBeforeUnmount, ref, shallowRef, onMounted, reactive, h } from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from "vue-router"
import { useNewsStore } from '@/store/newsStore'

const router = useRouter()
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;
const editorRef = shallowRef()
const toolbarConfig = {}
const editorConfig = { placeholder: '请输入内容...', uploadImgShowBase64: true }
const mode = ref('edit')

var news = reactive({
    title: '未命名',
    status: true,
    content: '',
    cover: '',
    author: '',
    link: '',
    release_date: '',
    collect_num: 0,
    like_num: 0,
    read_num: 0
})

var fileList = reactive([])
var setCoverType = ref('上传图片')

const _resetNews = () => {
    news = reactive({
        title: '未命名',
        status: true,
        content: '',
        cover: '',
        author: '',
        link: '',
        release_date: '',
        collect_num: 0,
        like_num: 0,
        read_num: 0
    })
}


const goBack = () => {
    router.go(-1)
}

const setFileList = (url) => {
    fileList = [{ name: url.split('/').pop().split('?').shift(), url: url }]
}

const handleCreated = (editor) => {
    editorRef.value = editor // 记录 editor 实例，重要！
}

const setEditorValue = (value) => {
    const editor = editorRef.value // 获取 editor ，必须等待它渲染完之后
    if (editor == null) return
    news.content = value
    editor.insertText(value) // 执行 editor API
}

const uploadCover = (obj) => {
    var filename = obj.file.name
    CBSDK.Common.uploadNewsCover(filename, obj.file).then(res => {
        news.cover = res.download_url
        setFileList(res.download_url)
    }).catch(err => {
        ElNotification({
            title: '图片上传失败',
            message: h('i', { style: 'color: teal' }, err),
            duration: 2000
        })
    })
}

const saveNews = () => {
    if (news._id == undefined || news._id == null || news._id == '') {
        CBSDK.News.create(news).then(res => {
            ElNotification({
                title: '操作成功',
                message: h('i', { style: 'color: teal' }, '已添加博客'),
                duration: 2000
            })
            goBack()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: h('i', { style: 'color: teal' }, '添加博客失败:' + err),
                duration: 2000
            })
        })
    } else {
        const newsObj = {
            title: news.title,
            status: true,
            is_free: false,
            is_recommend: false,
            content: news.content,
            cover: news.cover,
            author: news.author,
            link: news.link,
            release_date: news.release_date
        }
        CBSDK.News.update(news._id, newsObj).then(res => {
            ElNotification({
                title: '操作成功',
                message: h('i', { style: 'color: teal' }, '已修改博客'),
                duration: 2000
            })
            goBack()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: h('i', { style: 'color: teal' }, '修改博客失败:' + err),
                duration: 2000
            })
        })
    }
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
    const editor = editorRef.value
    if (editor == null) return
    editor.destroy()
})

onMounted(() => {
    const newsStore = useNewsStore()
    if (newsStore.has()) {
        news = newsStore.get()
        setFileList(news.cover)
        setEditorValue(news.content)
    } else {
        _resetNews()
    }
})

</script>
<style lang="scss">
.news_edit_view {
    width: 100%;

    head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;

        .el-upload__text {
            width: 100%;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>