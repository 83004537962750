
import baseConfig from './config'
import cloudbase from '@cloudbase/js-sdk'
import { isString, isNumber, isBoolean, isArray, isUndefined } from './dataType'

const base = class base {

    constructor() {
        this.app = cloudbase.init({
            env: baseConfig.ENV,
            region: baseConfig.REGION
        })

        
    }

    setDatabase(database) {
        this.db = this.app.database()
        this.collection = this.db.collection(database)
    }

    get(id) {
        return new Promise((resolve, reject) => {
            this.collection.doc(id).get().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    query(options) {
        var params = {}
        var _pageNum = 1;
        var _pageSize = 10;

        if (!isUndefined(options.pageNum) && isNumber(options.pageNum)) {
            _pageNum = options.pageNum;
        }
        if (!isUndefined(options.pageSize) && isNumber(options.pageSize)) {
            _pageSize = options.pageSize;
        }
        return new Promise((resolve, reject) => {
            this.collection.where(params).skip((_pageNum - 1) * _pageSize).limit(_pageSize).orderBy('create_time', 'desc').get().then(res => {
                // resolve(res.data)
                let data = res.data
                this.collection.where(params).count().then(res => {
                    resolve({data: data, total:res.total})
                }).catch(err => {
                    reject(err)
                })
            }).catch(err => {
                reject(err)
            })
        })
    }

    

    count() {
        var params = {}
        return new Promise((resolve, reject) => {
            this.collection.where(params).count().then(res => {
                resolve(res.total)
            }).catch(err => {
                reject(err)
            })
        })
    }

    create(object) {
        return new Promise((resolve, reject) => {
            object.create_time = new Date()
            object.update_time = new Date()
            this.collection.add(object).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, object) {
        return new Promise((resolve, reject) => {
            object.update_time = new Date()
            this.collection.doc(id).update(object).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    remove(id) {
        return new Promise((resolve, reject) => {
            this.collection.doc(id).remove().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default base