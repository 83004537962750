import base from './base'
import { isString, isNumber, isBoolean, isArray, isUndefined } from './dataType'
const dropPoint = class dropPoint extends base {

    constructor() {
        super()
        this.setDatabase('dropPoint')
    }

    find(options) {
        var params = {}
        if(!isUndefined(options)) {
            if(!isUndefined(options.name) && isString(options.name)) {
                let regExp = this.db.RegExp({
                    regexp: options.name,
                    options: 'i'
                })
                params.name = regExp
            }
        }
        return new Promise((resolve, reject) => {
            this.collection.where(params).orderBy('create_time', 'desc').get().then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    create(data) {
        var params = {
            methodName: "create"
        }
        if (!isUndefined(data)) {
            params.data = data
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'dropPoint', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, data) {
        var params = {
            methodName: "update"
        }
        if (!isUndefined(data)) {
            params.data = data
        }
        if(!isUndefined(id) && isString(id)) {
            params.id = id
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'dropPoint', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }

    query(options) {
        var params = {
            pageNum: 1,
            pageSize: 10,
        }

        if (!isUndefined(options.pageNum) && isNumber(options.pageNum)) {
            params.pageNum = options.pageNum;
        }
        if (!isUndefined(options.pageSize) && isNumber(options.pageSize)) {
            params.pageSize = options.pageSize;
        }
        if (!isUndefined(options.searchContent) && isString(options.searchContent))
        {
            params.searchContent = options.searchContent
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'queryDropPoint', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }

    removeDropPoint(id) {
        return new Promise((resolve, reject) => {
            this.app.callFunction({ name: 'removeDropPoint', data: { _id: id } }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default dropPoint

