<template>
   <div class="news_view">
        <div class="head">
            <h1>资讯管理</h1>
            <span class="add_btn">
                <el-button type="success" @click="_addNews" text bg>
                    <el-icon>
                        <Edit />
                    </el-icon>
                    新增
                </el-button>
            </span>
        </div>
        <el-divider />
        <div class="body">
            <div class="search_panel">
                <el-input style="width: 500px;" v-model="searchContent" placeholder="可根据标题进行查询" clearable >
                    <template #prepend>
                        <el-icon>
                        <Search />
                    </el-icon>
                    </template>
                </el-input>
                <el-button @click="_searchData">搜索</el-button>
            </div>
            <el-table :data="news" v-loading="tableLoading" flexible >
                <el-table-column type="expand">
                    <template #default="props">
                        <div>{{ props.row.content }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" width="80">
                    <template #default="scope">
                        <el-tag :type="scope.row.status ? 'success' : 'info'" size="small">{{ scope.row.status ? '启用' : '停用' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column prop="cover" label="封面">
                    <template #default="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.cover" :fit="fit" />
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建日期">
                    <template #default="scope">
                        {{ formatDate(scope.row.create_time) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200" fixed="right">
                    <template #default="scope">
                        <el-button type="text" @click="_editNews(scope.row)">编辑</el-button>
                        <el-button link type="warning" size="small" @click.prevent="_deleteNews(scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-pagination v-model:current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next"
                :total="total" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { getCurrentInstance, ref, onMounted } from 'vue'
import { useNewsStore } from '@/store/newsStore'
import moment from 'moment';

const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;
const router = useRouter()
const news = ref([])
const pageNum = ref(1)
const pageSize = ref(10)
const total = ref(0)
const searchContent = ref('')
const tableLoading = ref(false)
const _searchData = () => {
    
    pageNum.value = 1
    _loadNews()
}

const formatDate = (value) => {
    return moment(value).format('YYYY-MM-DD');
}

const _loadNews = () => {
    tableLoading.value = true
    CBSDK.News.query({
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        searchContent: searchContent.value
     }).then(res => {
        news.value = res.data
        total.value = res.total
        tableLoading.value = false
     }).catch(err => {
        tableLoading.value = false
    })
}

const handleCurrentChange = (val) => {
    pageNum.value = val
    _loadNews()
}

const _editNews = (obj) => {
    const newsStore = useNewsStore()
    newsStore.set(obj)
    router.push({ path: '/news/edit'})
}

const _deleteNews = (obj) => {
    ElMessageBox.confirm(
        '确定删除['+obj.title+']该资讯吗?',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    ).then(res => {
        CBSDK.News.remove(obj._id).then(res => {
            ElNotification({
                title: '操作成功',
                message: '资讯已删除',
                type: 'success'
            })
            _loadNews()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: '删除资讯失败:' + err,
                type: 'error'
            })
        })
    }).catch(err => {

    })
}

const _addNews = () => {
    const newsStore = useNewsStore()
    newsStore.clear()
    router.push({ path: '/news/edit'})
}

onMounted(() => {
    _loadNews()
})
</script>

<style lang="scss" scoped>
.news_view {
    width: 100%;
    .head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;

        .search_panel {
            margin: 10px 0;
            padding: 10px;
            display: flex;
            justify-content: start;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>