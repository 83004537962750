<template>
    <div class="customer_edit_view">
        <div class="head">
            <el-affix position="top" :offset="0">
                <el-card shadow="never">
                    <el-page-header @back="goBack">
                        <template #content>
                            <span class="text-large font-600 mr-3"> {{ customer._id ? '编辑' : '创建' }}客户
                            </span>
                        </template>
                        <template #extra>
                            <div class="flex items-center">
                                <el-button type="primary" class="ml-2" @click="saveCustomer">保存</el-button>
                            </div>
                        </template>
                    </el-page-header>
                </el-card>
            </el-affix>
        </div>

        <div class="body">
            <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="18">
                    <el-card>
                        <el-form  :model="customer" label-position="top">
                            <el-form-item label="姓名" >
                                <el-input v-model="customer.name" :disabled="searchCustomerFlag" style="width:216px;" />
                            </el-form-item>
                            <el-form-item label="联系电话">
                                <el-input v-model="customer.phone" :disabled="searchCustomerFlag" style="width:216px;" />
                            </el-form-item>
                            <el-form-item label="身份证" >
                                <el-input v-model="customer.id_card" :disabled="searchCustomerFlag" style="width:216px;" />
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="3"></el-col>
            </el-row>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router"
import { getCurrentInstance, ref, onMounted, reactive } from "vue";
import { ElNotification, ElLoading } from 'element-plus'
import { useCustomerStore } from '@/store/customerStore'
const router = useRouter()
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;



var customer = ref({
    name: '',
    phone: '',
    id_card: '',
    gender: 0,
    age: 0
})

const _resetCustomer = () => {
    customer = ref({
        name: '',
        phone: '',
        id_card: '',
        gender: 0,
        age: 0
    })
}

const goBack = () => {
    router.go(-1)
}

const saveCustomer = () => {
    if (customer.value.name == null || customer.value.name == '' || customer.value.name == undefined
        || customer.value.phone == null || customer.value.phone == '' || customer.value.phone == undefined
        || customer.value.id_card == null || customer.value.id_card == '' || customer.value.id_card == undefined) {
        ElNotification({
            title: '错误',
            message: '客户信息不完整',
            type: 'error',
        })
        return;
    }

    var idCardInfo = CBSDK.Utils.parseIdCard(customer.value.id_card)
    customer.value.age = idCardInfo.age
    customer.value.gender = idCardInfo.gender
    if (customer.value._id == undefined || customer.value._id == null || customer.value._id == '') {
        CBSDK.Customer.create(customer.value).then(res => {
            ElNotification({
                title: '操作成功',
                message: '已添加客户信息',
                type: 'scucess',
            })
            goBack()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: '添加客户信息错误:' + err,
                type: 'error',
            })
        })
    } else {
        const customerObj = {
            name: customer.value.name,
            phone: customer.value.phone,
            id_card: customer.value.id_card,
            gender: customer.value.gender,
            age: customer.value.age
        }
        CBSDK.Customer.update(customer.value._id, customerObj).then(res => {
            ElNotification({
                title: '操作成功',
                message: '已修客户信息',
                type: 'scucess',
            })
            goBack()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: '修客户信息错误:' + err,
                type: 'error',
            })
        })
    }
}


onMounted(() => {
    const customerStore = useCustomerStore()
    if (customerStore.has()) {
        customer.value = customerStore.get()
    } else {
        _resetCustomer()
    }
})

</script>

<style lang="scss" scoped>
.customer_edit_view {
    width: 100%;

    head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;

        .el-upload__text {
            width: 100%;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>