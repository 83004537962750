<template>
    <div class="drop_point_edit_view">
        <div class="head">
            <el-affix position="top" :offset="0">
                <el-card shadow="never">
                    <el-page-header @back="goBack">
                        <template #content>
                            <span class="text-large font-600 mr-3"> {{ dropPoint._id ? '编辑' : '创建' }}投放点 " {{
                                dropPoint.title }} "
                            </span>
                        </template>
                        <template #extra>
                            <div class="flex items-center">
                                <el-button type="primary" class="ml-2" @click="saveDropPoint">保存</el-button>
                            </div>
                        </template>
                    </el-page-header>
                </el-card>
            </el-affix>
        </div>

        <div class="body">
            <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="18">
                    <el-card>
                        <el-form :model="dropPoint" label-position="top">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="单位名称">
                                        <el-input v-model="dropPoint.name" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="联系电话">
                                        <el-input v-model="dropPoint.phone" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item>
                                <el-upload class="upload-demo" drag list-type="picture-card" v-model:file-list="fileList"
                                    action="#" multiple :limit="9" :http-request="uploadImage" :on-remove="removeImage"
                                    :before-upload="onUploadImageBefore" :on-progress="onUploadImageProgress"
                                    :on-success="onUploadImageSuccess">
                                    <el-icon>
                                        <Plus />
                                    </el-icon>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="介绍">
                                <el-input v-model="dropPoint.content" type="textarea" :autosize="{ minRows: 4 }" />
                            </el-form-item>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="详细地址">
                                        <el-input v-model="dropPoint.address" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="经度">
                                        <el-input type="number" v-model="dropPoint.point_x" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="纬度">
                                        <el-input type="number" v-model="dropPoint.point_y" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="坐标获取器">
                                        <el-button type="primary" @click="openGetPointWeb">打开前往</el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="状态">
                                        <el-switch v-model="dropPoint.status" />
                                    </el-form-item>
                                </el-col>

                            </el-row>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="3"></el-col>
            </el-row>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router"
import { getCurrentInstance, onBeforeUnmount, ref, shallowRef, onMounted, reactive, h } from "vue";
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus'
import { useDropPointStore } from '@/store/dropPointStore'
const router = useRouter()
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;

var dropPoint = reactive({
    name: '',
    phone: '',
    image_urls: [],
    content: '',
    address: '',
    point_x: 0,
    point_y: 0,
    status: true
})

const _resetDropPoint = () => {
    dropPoint = reactive({
        name: '',
        phone: '',
        image_urls: [],
        content: '',
        address: '',
        point_x: 0,
        point_y: 0,
        status: true
    })
}

var fileList = ref([])
const goBack = () => {
    router.go(-1)
}

const saveDropPoint = () => {
    if (dropPoint._id == undefined || dropPoint._id == null || dropPoint._id == '') {
        CBSDK.DropPoint.create(dropPoint).then(res => {
            ElNotification({
                title: '操作成功',
                message: h('i', { style: 'color: teal' }, '已添加投放点'),
                duration: 2000
            })
            goBack()
        })
    } else {
        const dropPointObj = {
            name: dropPoint.name,
            phone: dropPoint.phone,
            image_urls: dropPoint.image_urls,
            content: dropPoint.content,
            address: dropPoint.address,
            point_x: dropPoint.point_x,
            point_y: dropPoint.point_y,
            status: dropPoint.status
        }
        console.log(dropPointObj)
        CBSDK.DropPoint.update(dropPoint._id, dropPointObj).then(res => {
            ElNotification({
                title: '操作成功',
                message: h('i', { style: 'color: teal' }, '已修改投放点'),
                duration: 2000
            })
            goBack()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: h('i', { style: 'color: teal' }, '修改投放点失败:' + err),
                duration: 2000
            })
        })
    }
}

const setFileList = (url) => {
    fileList.value = updateTargetImage({ name: url.split('/').pop().split('?').shift(), url: url }, fileList.value)
}

const uploadImage = (obj) => {
    var filename = obj.file.name
    CBSDK.Common.uploadDropPointImage(filename, obj.file).then(res => {
        var images = dropPoint.image_urls
        images.push({ name: res.download_url.split('/').pop().split('?').shift(), url: res.download_url })
        setFileList(res.download_url)
        console.log(fileList.value)
        dropPoint.image_urls = images
    }).catch(err => {
        ElNotification({
            title: '图片上传失败',
            message: h('i', { style: 'color: teal' }, err),
            duration: 2000
        })
    })
}

const onUploadImageBefore = () => {
    console.log('上传图片前')
}

const onUploadImageProgress = (evt, file, files) => {
    console.log('上传图片中')
    console.log(evt)
}

const onUploadImageSuccess = () => {
    console.log('上传图片成功')
}

const removeTargetImage = (targetName, images) => {
    return images.filter(image => image.name !== targetName);
}


const updateTargetImage = (targetName, images) => {
    return images.map(image => {
        if (image.name === targetName.name) {
            // 这里是你想要修改的内容
            image = targetName;
        }
        return image;
    });
}

const removeImage = (uploadFile, uploadFiles) => {
    console.log(uploadFile)
    console.log(uploadFiles)
    dropPoint.image_urls = removeTargetImage(uploadFile.name, dropPoint.image_urls)
}

const openGetPointWeb = () => {
    //https://api.map.baidu.com/lbsapi/getpoint/index.html?qq-pf-to=pcqq.c2c
    window.open('https://api.map.baidu.com/lbsapi/getpoint/index.html?qq-pf-to=pcqq.c2c', '_blank');
}

onMounted(() => {
    const dropPointStore = useDropPointStore()
    if (dropPointStore.has()) {
        dropPoint = dropPointStore.get()
        fileList.value = dropPoint.image_urls
        console.log(fileList.value)
    } else {
        _resetDropPoint()
    }
})


</script>

<style scoped>
.drop_point_edit_view {
    width: 100%;

    head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;

        .el-upload__text {
            width: 100%;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>