import base from './base'
import { isUndefined,isArray,isNumber,isBoolean,isString } from './dataType'

const investDevice = class investDevice extends base {

    constructor() {
        super()
        this.setDatabase('investDevice')
    }

    createInvestDevices(investDevices) {
        
        return new Promise((resolve, reject) => {
            if(!isUndefined(investDevices) && isArray(investDevices)) {
                this.app.callFunction({name: 'createInvestDevices', data: {invests: JSON.stringify(investDevices)}}).then(res => {
                    resolve(res.result)
                }).catch(err => {
                    reject(err)
                })
            } else {
                reject('缺少参数')
            }        
        })
    }

    removeInvestDevice(investDevice) {
        return new Promise((resolve, reject) => {
            if(!isUndefined(investDevice)) {
                this.app.callFunction({name: 'removeInvestDevices', data: {invest: JSON.stringify(investDevice)}}).then(res => {
                    resolve(res.result)
                }).catch(err => {
                    reject(err)
                })
            } else {
                reject('缺少参数')
            }
        })
    }

    query(options) {
        var params = {
            pageNum: 1,
            pageSize: 10,
        }

        if (!isUndefined(options.pageNum) && isNumber(options.pageNum)) {
            params.pageNum = options.pageNum;
        }
        if (!isUndefined(options.pageSize) && isNumber(options.pageSize)) {
            params.pageSize = options.pageSize;
        }
        if (!isUndefined(options.searchContent) && isString(options.searchContent)) {
            params.searchContent = options.searchContent;
        }
        if (!isUndefined(options.distributable) && isBoolean(options.distributable)) {
            params.distributable = options.distributable;
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'queryInvestDevices', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default investDevice