import { defineStore } from 'pinia'

export const useInvestDeviceStore = defineStore({
    id: 'investDevice',
    state: () => ({
        investDevice: null
    }),
    actions: {
        set(investDevice) {
            this.investDevice = investDevice
        },
        get() {
            return this.investDevice
        },
        has() {
            return this.investDevice !== null && this.investDevice !== undefined && this.investDevice !== ''
        },
        clear() {
            this.investDevice = null
        }
    }
})