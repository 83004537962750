import base from './base'
import { isString, isNumber, isBoolean, isArray, isUndefined } from './dataType'
const device = class device extends base {

    constructor() {
        super()
        this.setDatabase('device')
    }

    getDeviceNumByDropPointId(drop_point_id) {
        var params = {}
        return new Promise((resolve, reject) => {
            this.collection.where(params).count().then(res => {
                resolve({drop_point:{_id: drop_point_id}})
            }).catch(err => {
                reject(err)
            })
        })
    }

    create(data) {
        var params = {
            methodName: "create"
        }
        if (!isUndefined(data)) {
            params.data = data
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'device', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, data) {
        var params = {
            methodName: "update"
        }
        if (!isUndefined(data)) {
            params.data = data
        }
        if(!isUndefined(id) && isString(id)) {
            params.id = id
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'device', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }

    query(options) {
        var params = {
            pageNum: 1,
            pageSize: 10,
        }

        if (!isUndefined(options.pageNum) && isNumber(options.pageNum)) {
            params.pageNum = options.pageNum;
        }
        if (!isUndefined(options.pageSize) && isNumber(options.pageSize)) {
            params.pageSize = options.pageSize;
        }
        if (!isUndefined(options.searchContent) && isString(options.searchContent)) {
            params.searchContent = options.searchContent;
        }
        if (!isUndefined(options.distributable) && isBoolean(options.distributable)) {
            params.distributable = options.distributable;
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'queryDevice', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }

    removeDevice(id) {
        return new Promise((resolve, reject) => {
            this.app.callFunction({ name: 'removeDevice', data: { _id: id } }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default device