import baseConfig from './config'
import cloudbase from "@cloudbase/js-sdk"

const utils = class utils {

    constructor() {

    }

    parseIdCard(idCard) {
        // 提取出生日期
        const birthYear = idCard.substring(6, 10);
        const birthMonth = idCard.substring(10, 12);
        const birthDay = idCard.substring(12, 14);
        const birthday = new Date(`${birthYear}-${birthMonth}-${birthDay}`);

        // 计算年龄
        const now = new Date();
        let age = now.getFullYear() - birthday.getFullYear();
        if (now.getMonth() < birthday.getMonth() || (now.getMonth() == birthday.getMonth() && now.getDate() < birthday.getDate())) {
            age--;
        }

        // 提取性别
        const genderCode = idCard.substring(16, 17);
        const gender = genderCode % 2 === 0 ? 2 : 1;

        return { age, gender };
    }

}

export default utils;