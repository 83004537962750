import base from './base'
import { isString, isNumber, isBoolean, isArray, isUndefined } from './dataType'

const appletUser = class appletUser extends base {

    constructor() {
        super()
        this.setDatabase('applet_user')
    }

    query(options) {
        var params = {
            pageNum: 1,
            pageSize: 10,
        }
        if (!isUndefined(options.pageNum) && isNumber(options.pageNum)) {
            params.pageNum = options.pageNum;
        }
        if (!isUndefined(options.pageSize) && isNumber(options.pageSize)) {
            params.pageSize = options.pageSize;
        }
        if (!isUndefined(options.searchContent) && isString(options.searchContent)) {
            params.searchContent = options.searchContent;
        }

        return new Promise((resolve, reject) => {
            this.app.callFunction({
                name: 'queryAppletUsers',
                data: params
            }).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
        
    }

    updateAppletUserType(options) {
        var params = {}
        if (!isUndefined(options._id) && isString(options._id)) {
            params._id = options._id;
        }
        if (!isUndefined(options.type) && isNumber(options.type)) {
            params.type = options.type;
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({
                name: 'updateAppletUserType',
                data: params
            }).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }

    connectCustomerInfo(options) {
        var params = {}
        if (!isUndefined(options.applet_user_id) && isString(options.applet_user_id)) {
            params.applet_user_id = options.applet_user_id;
        }
        if (!isUndefined(options.connect_type) && isNumber(options.connect_type)) {
            params.connect_type = options.connect_type;
        }
        if (!isUndefined(options.code) && isString(options.code)) {
            params.code = options.code;
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({
                name: 'connectCustomerInfo',
                data: params
            }).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default appletUser