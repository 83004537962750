<template>
    <div class="invest_device_eidt_view">
        <div class="head">
            <el-affix position="top" :offset="0">
                <el-card shadow="never">
                    <el-page-header @back="goBack">
                        <template #content>
                            <span class="text-large font-600 mr-3">
                                编辑
                            </span>
                        </template>
                        <template #extra>
                            <div class="flex items-center">
                                <el-button type="primary" class="ml-2" @click="saveInvestDevice">保存</el-button>
                            </div>
                        </template>
                    </el-page-header>
                </el-card>
            </el-affix>
        </div>

        <div class="body">
            <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="18">
                    <el-card style="margin-top:10px;">

                        <el-form :rules="rules" :model="customer" label-width="auto" label-position="right">
                            <el-row :gutter="5">
                                <el-col :span="12">
                                    <el-divider content-position="left">客户信息</el-divider>
                                    <el-form-item label="搜索现有客户">
                                        <el-switch v-model="searchCustomerFlag" />
                                    </el-form-item>

                                    <el-form-item v-if="searchCustomerFlag" label="搜索客户">
                                        <el-select v-model="customer" filterable remote reserve-keyword value-key="_id"
                                            remote-show-suffix placeholder="请输入客户姓名" :remote-method="remoteFindCustomer"
                                            :loading="loading" @change="selectCustomerChange">
                                            <el-option v-for="item in customerOptions" :key="item"
                                                :label="item.name + '(' + item.id_card + ')'" :value="item" />
                                        </el-select>
                                        <el-button @click="resetCustomer">清除</el-button>
                                    </el-form-item>
                                    <el-form-item label="姓名" prop="name">
                                        <el-input v-model="customer.name" :disabled="searchCustomerFlag"
                                            style="width:216px;" />
                                    </el-form-item>
                                    <el-form-item label="联系电话" prop="phone">
                                        <el-input v-model="customer.phone" :disabled="searchCustomerFlag"
                                            style="width:216px;" />
                                    </el-form-item>
                                    <el-form-item label="身份证" prop="id_card">
                                        <el-input v-model="customer.id_card" :disabled="searchCustomerFlag"
                                            style="width:216px;" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="1">
                                    <el-divider direction="vertical" style="height:300px;" border-style="dashed" />
                                </el-col>

                                <el-col :span="11">
                                    <el-divider content-position="left">有效期</el-divider>
                                    <el-form-item label="有效期">
                                        <el-select v-model="effective_duration" @change="effectiveDurationChangeHandle">
                                            <el-option v-for="item in effectiveDurationOptions" :key="item.value"
                                                :label="item.label" :value="item.value" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="起始日期">
                                        <el-date-picker v-model="start_time" type="date" placeholder="选择日期"
                                            style="width:216px;" @change="startTimeChangeHandle" />

                                    </el-form-item>
                                    <el-form-item label="结束日期">
                                        <el-date-picker v-model="end_time" disabled type="date" style="width:216px;" />
                                    </el-form-item>
                                </el-col>
                            </el-row>



                            <el-divider content-position="left">配置设备</el-divider>
                            <el-form-item label="选择设备">
                                <el-transfer v-model="selectDevices" :props="{
                                    key: '_id',
                                    label: 'code',
                                }" filterable :filter-method="filterMethod" filter-placeholder="设备编码"
                                    :data="usableDevices" :titles="transfetTitles" @change="selectDevicesChange" />
                            </el-form-item>
                            <el-divider content-position="left">设置设备持有比例</el-divider>
                            <el-form-item v-for="(invest, index) in investDevices" :key="invest.code"
                                :label="'设备[' + (index + 1) + ']'">
                                <el-tag size="large" style="margin-right:10px;">{{ invest.code }}</el-tag>
                                &nbsp;&nbsp;<el-slider v-model="invest.distributed_amount" :marks="marks"
                                    :max="invest.max_distributed_amount" style="width:500px;" show-input />
                                <el-divider border-style="dashed" />
                            </el-form-item>

                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="3"></el-col>
            </el-row>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router"
import { getCurrentInstance, ref, onMounted, reactive } from "vue";
import { ElNotification, ElLoading } from 'element-plus'
const router = useRouter()
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;



const rules = reactive({
    name: [
        { required: true, message: '请填写客户姓名', trigger: 'blur' }
    ],
    phone: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
    ],
    id_card: [
        { required: true, message: '请输入身份证号码', trigger: 'blur' },
        { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号码格式不正确', trigger: 'blur' }
    ]
})

const loading = ref(false)
const customerOptions = ref([])
const searchCustomerFlag = ref(false)
var customer = ref({
    _id: '',
    name: '',
    phone: '',
    id_card: '',
    gender: 0,
    age: 0
})

const resetCustomer = () => {
    customer.value = {
        _id: '',
        name: '',
        phone: '',
        id_card: '',
        gender: 0,
        age: 0
    }
}

const remoteFindCustomer = (query) => {
    if (query) {
        CBSDK.Customer.find({
            name: query
        }).then(res => {
            loading.value = false
            customerOptions.value = res
        }).catch(err => {
            loading.value = false
        })
    }
}

const selectCustomerChange = (value) => {
    customer.value = value
}

const usableDevices = ref([]) //可用设备
const selectDevices = ref([]) //已选设备

const investDevices = ref([])

const _loadUsableDevices = () => {
    CBSDK.Device.query({
        pageNum: 1,
        pageSize: 1000,
        distributable: true
    }).then(res => {
        usableDevices.value = res.data
    })
}



const filterMethod = (query, item) => {
    return item.code ? item.code.toLowerCase().includes(query.toLowerCase()) : false;
}

const selectDevicesChange = () => {
    investDevices.value = withdrawDevices(usableDevices.value, selectDevices.value)
}

//提取设备
const withdrawDevices = (datas, selectDataIds) => {

    // 然后，我们过滤datas数组，只保留那些其_id存在于ids数组中的元素
    const filteredDatas = datas.filter(data => selectDataIds.includes(data._id));

    // 然后，我们过滤datas数组，只保留那些其_id存在于ids数组中的元素
    const tempDevices = filteredDatas.map(({ _id, name, code, distributed_amount, drop_point_id }) => ({
        device_id: _id,
        device_name: name,
        code,
        // max_distributed_amount: 100 - distributed_amount,
        max_distributed_amount: 100,
        distributed_amount: 0,
        drop_point_id,
        start_time: null,
        end_time: null,
        effective_duration: null,
        holder_id: null
    }));

    return tempDevices;
}

var transfetTitles = ["可选设备", "已选设备"]

const marks = reactive({
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%',
})

const start_time = ref(null)
const end_time = ref(null)

const effective_duration = ref(12)
const effectiveDurationOptions = [
    { value: 3, label: '3个月' },
    { value: 6, label: '6个月' },
    { value: 12, label: '12个月' },
    { value: 18, label: '18个月' },
    { value: 24, label: '24个月' },
    { value: 30, label: '30个月' },
    { value: 36, label: '36个月' }
]

const startTimeChangeHandle = (val) => {
    const newDate = new Date(val)
    newDate.setMonth(newDate.getMonth() + effective_duration.value)
    end_time.value = newDate
}

const effectiveDurationChangeHandle = (val) => {
    if (start_time.value) {
        const newDate = new Date(start_time.value)
        newDate.setMonth(newDate.getMonth() + val)
        end_time.value = newDate
    }
}

const goBack = () => {
    router.go(-1)
}

//创建客户
// const createCustomer = async () => {
//     var customerObj = customer.value
//     var idCardInfo = CBSDK.Utils.parseIdCard(customerObj.id_card)
//     customerObj.age = idCardInfo.age
//     customerObj.gender = idCardInfo.gender
//     await CBSDK.Customer.create(customerObj).then(res => {
//         CBSDK.Customer.get(res.id).then(res => {
//             customer.value = res.data[0]
//         })
//     }).catch(err => {
//         ElNotification({
//             title: '错误',
//             message: err,
//             type: 'error',
//         })
//     })
// }


const saveInvestDevice = async () => {
    //验证
    if (!start_time.value) {
        ElNotification({
            title: '错误',
            message: '开始日期未设置',
            type: 'error',
        })
        return;
    }

    if (!end_time.value) {
        ElNotification({
            title: '错误',
            message: '结束日期未设置',
            type: 'error',
        })
        return;
    }

    if (!investDevices.value || investDevices.value.length == 0) {
        ElNotification({
            title: '错误',
            message: '未分配设备',
            type: 'error',
        })
        return;
    }
    var tempInvest = null
    for (let i = 0; i < investDevices.value.length; i++) {
        if (investDevices.value[i].distributed_amount == 0) {
            tempInvest = investDevices.value[i]
            break;
        }
    }

    if (tempInvest) {
        ElNotification({
            title: '错误',
            message: '设备[' + tempInvest.code + ']未设置占比',
            type: 'error',
        })
        return;
    }

    if (customer.value.name == null || customer.value.name == '' || customer.value.name == undefined
        || customer.value.phone == null || customer.value.phone == '' || customer.value.phone == undefined
        || customer.value.id_card == null || customer.value.id_card == '' || customer.value.id_card == undefined) {
        ElNotification({
            title: '错误',
            message: '客户信息不完整',
            type: 'error',
        })
        return;
    }

    const saveLoading = ElLoading.service({
        lock: true,
        text: '数据处理中',
        background: 'rgba(0, 0, 0, 0.7)',
    })

    //身份
    if (!searchCustomerFlag.value) {
        var idCardExist = await CBSDK.Customer.checkExist(customer.value.id_card)
        if (idCardExist) {
            saveLoading.close()
            ElNotification({
                title: '错误',
                message: '客服身份证信息重复,请检测客服是否已存在',
                type: 'error',
            })
            return;
        }
        //创建用户
        // await createCustomer()
        var customerObj = customer.value
        var idCardInfo = CBSDK.Utils.parseIdCard(customerObj.id_card)
        customerObj.age = idCardInfo.age
        customerObj.gender = idCardInfo.gender
        await CBSDK.Customer.create(customerObj).then(res => {
            CBSDK.Customer.get(res.id).then(res => {
                customer.value = res.data[0]
                var tempInvestDeviceObjs = investDevices.value
                var investDeviceObjs = tempInvestDeviceObjs.map(({ device_id, distributed_amount, drop_point_id }) => ({
                    device_id,
                    distributed_amount,
                    drop_point_id,
                    start_time: null,
                    end_time: null,
                    effective_duration: null,
                    holder_id: null
                }));
                for (let i = 0; i < investDeviceObjs.length; i++) {
                    investDeviceObjs[i].holder_id = customer.value
                    investDeviceObjs[i].start_time = start_time.value
                    investDeviceObjs[i].end_time = end_time.value
                    investDeviceObjs[i].effective_duration = effective_duration.value
                }
                CBSDK.InvestDevice.createInvestDevices(investDeviceObjs).then(res => {

                    saveLoading.close()
                    goBack()
                }).catch(err => {
                    saveLoading.close()
                    ElNotification({
                        title: '错误',
                        message: err,
                        type: 'error',
                    })
                })
            })
        }).catch(err => {
            ElNotification({
                title: '错误',
                message: err,
                type: 'error',
            })
        })
    } else {
        var tempInvestDeviceObjs = investDevices.value
        var investDeviceObjs = tempInvestDeviceObjs.map(({ device_id, distributed_amount, drop_point_id }) => ({
            device_id,
            distributed_amount,
            drop_point_id,
            start_time: null,
            end_time: null,
            effective_duration: null,
            holder_id: null
        }));
        for (let i = 0; i < investDeviceObjs.length; i++) {
            investDeviceObjs[i].holder_id = customer.value._id
            investDeviceObjs[i].start_time = start_time.value
            investDeviceObjs[i].end_time = end_time.value
            investDeviceObjs[i].effective_duration = effective_duration.value
        }
        CBSDK.InvestDevice.createInvestDevices(investDeviceObjs).then(res => {

            saveLoading.close()
            goBack()
        }).catch(err => {
            saveLoading.close()
            ElNotification({
                title: '错误',
                message: err,
                type: 'error',
            })
        })
    }

}

onMounted(() => {
    _loadUsableDevices()
})

</script>

<style lang="scss" scoped>
.drop_point_edit_view {
    width: 100%;

    head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 10px;

        .el-upload__text {
            width: 100%;
        }
    }

    .footer {
        text-align: center;
    }
}

.el-transfer {
    --el-transfer-panel-width: 240px;
}
</style>