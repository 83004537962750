import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import LoginView from '../views/Login.vue'
import HomeView from '../views/Home/index.vue'
import SignUp from '../views/SignUp'
import DropPointView from '../views/Home/DropPoint/DropPointView.vue'
import DropPointEditView from '../views/Home/DropPoint/DropPointEditView.vue'
import DeviceView from '../views/Home/Device/DeviceView.vue'
import DeviceEditView from '../views/Home/Device/DeviceEditView.vue'
import InvestDeviceView from '../views/Home/InvestDevice/InvestDeviceView.vue'
import InvestDeviceEditView from '../views/Home/InvestDevice/InvestDeviceEditView.vue'
import CustomerView from '../views/Home/Customer/CustomerView.vue'
import CustomerEditView from '../views/Home/Customer/CustomerEditView.vue'
import NewsView from '../views/Home/News/NewsView.vue'
import NewsEditView from '../views/Home/News/NewsEditView.vue'
import ConsultView from '../views/Home/Consult/ConsultView.vue'
import AppletUserView from '../views/Home/AppletUser/AppletUserView.vue'
import InvestDeviceExportView from '../views/Home/InvestDevice/InvestDeviceExportView.vue'
import InvestDeviceImportView from '../views/Home/InvestDevice/InvestDeviceImportView.vue'
import PublicityView from '../views/Home/Publicity/PublicityView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requireAuth: true },
    component: HomeView,
    children: [{
      path: '/dropPoint',
      name: 'DropPointView',
      component: DropPointView
    },{
      path: '/dropPoint/edit',
      name: 'DropPointEditView',
      component: DropPointEditView
    },{
      path: '/device',
      name: 'DeviceView',
      component: DeviceView
    },{
      path: '/device/edit',
      name: 'DeviceEditView',
      component: DeviceEditView
    },{
      path: '/invest',
      name: 'InvestDeviceView',
      component: InvestDeviceView
    },{
      path: '/invest/edit',
      name: 'InvestDeviceEditView',
      component: InvestDeviceEditView
    },{
      path: '/invest/export',
      name: 'InvestDeviceExportView',
      component: InvestDeviceExportView
    },{
      path: '/invest/import',
      name: 'InvestDeviceImportView',
      component: InvestDeviceImportView
    },{
      path: '/customer',
      name: 'CustomerView',
      component: CustomerView
    },{
      path: '/customer/edit',
      name: 'CustomerEditView',
      component: CustomerEditView
    },{
      path: '/news',
      name: 'NewsView',
      component: NewsView
    },{
      path: '/news/edit',
      name: 'NewsEditView',
      component: NewsEditView
    },{
      path: '/consult',
      name: 'ConsultView',
      component: ConsultView
    },{
      path: '/appletUser',
      name: 'AppletUserView',
      component: AppletUserView
    },{
      path: '/publicity',
      name: 'PublicityView',
      component: PublicityView
    }]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: SignUp
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.requireAuth)) {// 判断是否需要登录权限
    // console.log('需要登录')
    if (CBSDK.User.hasLoginState() != null) {
      if (to.path === '/') {
        next('/dropPoint');
      } else {
        next();
      }
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    // console.log('不需要登录')
    next()
  }
})
export default router
