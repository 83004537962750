import { defineStore } from 'pinia'

export const useCustomerStore = defineStore({
    id: 'customer',
    state: () => ({
        customer: null
    }),
    actions: {
        set(customer) {
            this.customer = customer
        },
        get() {
            return this.customer
        },
        has() {
            return this.customer !== null && this.customer !== undefined && this.customer !== ''
        },
        clear() {
            this.customer = null
        }
    }
})