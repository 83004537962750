<template>
    <div class="invest_device_import_view">
        <div class="head">
            <el-affix position="top" :offset="0">
                <el-card shadow="never">
                    <el-page-header @back="goBack">
                        <template #content>
                            <span class="text-large font-600 mr-3">
                                设备分配数据导入
                            </span>
                        </template>
                    </el-page-header>
                </el-card>
            </el-affix>
        </div>
        <div class="body">
            <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="18">
                    <el-card style="margin-top:10px;">
                        <!-- <el-button @click="uploadChange" type="primary">导入</el-button> -->
                        <el-upload ref="upload" class="upload-demo" action="#" :limit="1"
                            :on-change="onChangeUploadFile" :on-exceed="handleExceed" :auto-upload="false">
                            <template #trigger>
                                <el-button type="primary">选择文件</el-button>
                            </template>
                            <el-button class="ml-3" type="success" @click="readFile">
                                读取文件
                            </el-button>
                            <template #tip>
                                <div class="el-upload__tip text-red">
                                    限制一次一个文件,当选择新文件会覆盖旧文件
                                </div>
                            </template>
                        </el-upload>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router"
import { getCurrentInstance, ref, onMounted, reactive } from "vue";
import { ElNotification, ElLoading } from 'element-plus'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const router = useRouter()
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;

const uploadFile = ref(null)

const onChangeUploadFile = (file) => {
    uploadFile.value = file
}



const handleExceed = (files) => {    
    console.log(files)
    uploadFile.value = files[0]
}

// 上传文件并获取 file 对象
const readFile = async () => {

    if(uploadFile.value == null) {
        return
    }
    console.log(uploadFile.value)
    const files = uploadFile.value.raw;
    console.log(files)
    console.log(files.name)
    console.log(files.name.toLowerCase())
    if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        console.log("上传格式不正确，请上传 xls 或者 xlsx 格式");
        return false;
    }

    // 读取表格
    const fileReader = new FileReader();
    fileReader.onload = (ev) => {
        const workbook = XLSX.read(ev.target.result, { type: "binary" });
        const wsname = workbook.SheetNames[0];
        const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname],{header: 1, range:4});
        console.log(ws); // 这就是转换成 json 的数据
    };
    fileReader.readAsBinaryString(files);
};

const goBack = () => {
    router.go(-1)
}
</script>

<style lang="scss" scoped>
.invest_device_import_view {
    width: 100%;

    head {
        position: relative;

        h1 {
            text-align: left;
        }

    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 10px;

    }

    .footer {
        text-align: center;
    }
}
</style>