<template>
    <div class="sign_up_page">
        <el-row>
            <el-col :span="12">
                <el-image class="logo" style="" :src="logoPath" :fit="fit" />
                <div class="title">小程序管理后台</div>
                <div><el-tag type="success" @click="openBAURL">湘ICP备2023027506号</el-tag></div>
            </el-col>
            <el-col :span="12" class="right_panel" :style="windowHeight">
                <div class="login_panel">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="ruleForm.username"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input type="password" v-model="ruleForm.password"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="repassword">
                            <el-input type="repassword" v-model="ruleForm.repassword"></el-input>
                        </el-form-item>
                        <el-form-item label="邀请码" prop="InvitationCode">
                            <el-input v-model="ruleForm.InvitationCode"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">注册</el-button>
                            <el-button @click="resetForm('ruleForm')">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus'
var self;
export default {
    name: 'SignUp',
    data() {
        return {
            windowHeight: { height: '' },
            logoPath: '/jyhb-logo.jpg',
            ruleForm: {
                email: '',
                password: '',
                repassword: '',
                InvitationCode: ''
            }
        }
    },
    components: {},
    mounted() {
        self = this
        self.windowHeight.height = window.innerHeight + 'px';
        window.addEventListener("resize", this.myEventHandler);
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        myEventHandler(e) {
            // your code for handling resize...
            self.windowHeight.height = window.innerHeight + 'px';
        },
        submitForm(formName) {
            self.$refs[formName].validate((valid) => {
                if (valid) {
                    self.CBSDK.User.signUpByEmail(self.ruleForm.username, self.ruleForm.password, self.ruleForm.repassword, self.ruleForm.InvitationCode).then(res => {
                        ElNotification({
                            title: '注册成功',
                            message: '注册成功,请签收邮件进行激活',
                            type: 'success'
                        })
                        self.$router.push("/login")
                    }).catch(err => {
                        console.error(err)
                        ElNotification({
                            title: '错误',
                            message: err,
                            type: 'error'
                        })
                    })
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        openBAURL() {
            window.open('https://beian.miit.gov.cn/', '_blank');
        }
    }
}
</script>
<style lang="scss">
.sign_up_page {
    width: 100%;

    .title {
        color: #008d4a;
        font-size: 42px;
        font-weight: 800;
    }

    .right_panel {
        position: relative;
        background-color: #008d4a;
    }

    .login_panel {
        position: absolute;
        left: 0;
        right: 0;
        top: 320px;
        margin: auto;
        width: 450px;
        height: 300px;
        border-radius: 50px;
        background: #008d4a;
        box-shadow: 20px 20px 60px #00783f,
            -20px -20px 60px #00a255;
        padding: 10px;
    }

    .logo {
        margin-top: 300px;
        width: 300px;
        height: 300px
    }


    .el-form {
        margin-top: 40px;
        margin-right: 20px;
    }

    .el-form-item__label {
        color: #ffffff;
    }
}
</style>