 import baseConfig from './config'
 import cloudbase from "@cloudbase/js-sdk"

//  import {isString, isNumber, isBoolean, isArray, isUndefined } from './dataType'
 
 const user = class user {
    constructor() {

        //08ef119c6d56b20ca5c2417d6f70649a
        this.app = cloudbase.init({
            env: baseConfig.ENV,
            region: baseConfig.REGION
            
        })
        //clientId: 'AOJ8yAABLjU-Td1ukc4'
        // this.auth = this.app.auth({persistence: 'local'})
        this.db = this.app.database()
    }

    getCurrentUser() {
        return this.app.auth().currentUser;
    }

    hasLoginState() {
        var loginState = this.app.auth().hasLoginState();
        return loginState
    }

    //账号登录
    signInByAccount(username, password) {
        return new Promise((resolve, reject) => {
            this.auth.signInWithUsernameAndPassword(username, password).then((loginState) => {
                resolve(loginState)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    //邮箱注册
    signUpByEmail(email, password,repassword,InvitationCode) {
        return new Promise((resolve, reject) => {
            if(password != repassword) {
                reject('两次密码不一致')
                return
            }

            if(InvitationCode != 'JINyan777777') {
                reject('邀请码错误')
                return
            }

            this.app.auth().signUpWithEmailAndPassword(email, password).then((loginState) => {
                resolve(loginState)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    //邮箱登录
    signInByEmail(email, password) {
        return new Promise((resolve, reject) => {
            this.app.auth().signInWithEmailAndPassword(email, password).then((loginState) => {
                resolve(loginState)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    logout() {
        this.app.auth().signOut();
    }
 }

 export default user;