import base from './base'
import { isString, isNumber, isBoolean, isArray, isUndefined } from './dataType'
const consult = class consult extends base {

    constructor() {
        super()
        this.setDatabase('consult')
    }

    updateStatus(id, data) {
        var params = {}
        if (!isUndefined(data)) {
            params.data = data
        }
        if(!isUndefined(id) && isString(id)) {
            params.id = id
        }
        return new Promise((resolve, reject) => {
            this.app.callFunction({name: 'updateConsult', data: params}).then(res => {
                resolve(res.result)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default consult