import { defineStore } from 'pinia'

export const useDeviceStore = defineStore({
    id: 'device',
    state: () => ({
        device: null
    }),
    actions: {
        set(device) {
            this.device = device
        },
        get() {
            return this.device
        },
        has() {
            return this.device !== null && this.device !== undefined && this.device !== ''
        },
        clear() {
            this.device = null
        }
    }
})