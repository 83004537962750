<template>
    <div class="drop_point_view">
        <div class="head">
            <h1>投放点管理</h1>
            <span class="add_btn">
                <el-button type="success" @click="_addDropPoint" text bg>
                    <el-icon>
                        <Edit />
                    </el-icon>
                    新增
                </el-button>
            </span>
        </div>
        <el-divider />
        <div class="body">
            <div class="search_panel">
                <el-input style="width: 500px;" v-model="searchContent" placeholder="可根据名称进行查询" clearable >
                    <template #prepend>
                        <el-icon>
                        <Search />
                    </el-icon>
                    </template>
                </el-input>
                <el-button @click="_searchData">搜索</el-button>
            </div>
            <el-table :data="dropPoints" v-loading="tableLoading">
                <el-table-column type="expand">
                    <template #default="props">
                        <div>{{ props.row.content }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" width="80">
                    <template #default="scope">
                        <el-tag :type="scope.row.status ? 'success' : 'info'" size="small">{{ scope.row.status ? '启用' : '停用'
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="单位名称"></el-table-column>
                <el-table-column prop="phone" label="联系电话"></el-table-column>
                <el-table-column prop="address" label="详细地址"></el-table-column>
                <el-table-column prop="point_x" label="经度"></el-table-column>
                <el-table-column prop="point_y" label="纬度"></el-table-column>
                <el-table-column prop="devices.length" label="设备数">
                </el-table-column>
                <el-table-column label="操作" width="200" fixed="right">
                    <template #default="scope">
                        <el-button type="text" @click="_editDropPoint(scope.row)">编辑</el-button>
                        <el-button link type="warning" size="small" @click.prevent="_deleteDropPoint(scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-pagination v-model:current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next"
                :total="total" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { getCurrentInstance, ref, onMounted } from 'vue'
import { useDropPointStore } from '@/store/dropPointStore'
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus'
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;
const router = useRouter()
const dropPoints = ref([])
const pageNum = ref(1)
const pageSize = ref(10)
const total = ref(0)
const searchContent = ref('')
const tableLoading = ref(false)
const _searchData = () => {
    
    pageNum.value = 1
    _loadDropPoints()
}

const _loadDropPoints = () => {
    tableLoading.value = true
    CBSDK.DropPoint.query({
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        searchContent: searchContent.value
    }).then(res => {
    console.log(res)
        dropPoints.value = res.data
        total.value = res.total
        tableLoading.value = false
    }).catch(err => {
        tableLoading.value = false
    })
}

const handleCurrentChange = (val) => {
    pageNum.value = val
    _loadDropPoints()
}

const _editDropPoint = (obj) => {
    const dropPointStore = useDropPointStore()
    dropPointStore.set(obj)
    router.push({ path: '/dropPoint/edit' })
}

const _deleteDropPoint = (obj) => {
    ElMessageBox.confirm(
        '确定删除[' + obj.name + ']该投放点吗?',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    ).then(res => {
        CBSDK.DropPoint.removeDropPoint(obj._id).then(res => {
            ElNotification({
                title: '操作成功',
                message: '投放点已删除',
                type: 'success'
            })
            _loadDropPoints()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: '删除投放点失败:' + err,
                type: 'error'
            })
        })
    }).catch(err => {

    })
}

const _addDropPoint = () => {
    const dropPointStore = useDropPointStore()
    dropPointStore.clear()
    router.push({ path: '/dropPoint/edit' })
}

onMounted(() => {
    _loadDropPoints()
})

</script>

<style scoped lang="scss">
.drop_point_view {
    width: 100%;

    .head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;

        .search_panel {
            margin: 10px 0;
            padding: 10px;
            display: flex;
            justify-content: start;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>

