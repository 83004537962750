<template>
    <div class="customer_view">
        <div class="head">
            <h1>客户管理</h1>
            <span class="add_btn">
                <el-button type="success" @click="_addCustomer" text bg>
                    <el-icon>
                        <Edit />
                    </el-icon>
                    新增
                </el-button>
            </span>
        </div>
        <el-divider />
        <div class="body">
            <div class="search_panel">
                <el-input style="width: 500px;" v-model="searchContent" placeholder="可根据名称或手机号进行查询" clearable >
                    <template #prepend>
                        <el-icon>
                        <Search />
                    </el-icon>
                    </template>
                </el-input>
                <el-button @click="_searchData">搜索</el-button>
            </div>
            <el-table :data="customers" v-loading="tableLoading">
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="gender" label="性别">
                    <template #default="scope">
                        {{ scope.row.gender == '1' ? '男' : '女' }}
                    </template>
                </el-table-column>
                <el-table-column prop="age" label="年龄"></el-table-column>
                <el-table-column prop="id_card" label="身份证"></el-table-column>
                <el-table-column prop="phone" label="联系电话"></el-table-column>
                <el-table-column label="操作" width="200" fixed="right">
                    <template #default="scope">
                        <el-button type="text" @click="_editCustomer(scope.row)">编辑</el-button>
                        <el-button link type="warning" size="small" @click.prevent="_deleteCustomer(scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-pagination v-model:current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next"
                :total="total" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { getCurrentInstance, ref, onMounted } from 'vue'
import { useCustomerStore } from '@/store/customerStore'
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus'
const { appContext } = getCurrentInstance();
const CBSDK = appContext.app.config.globalProperties.CBSDK;
const router = useRouter()
const customers = ref([])
const pageNum = ref(1)
const pageSize = ref(10)
const total = ref(0)
const searchContent = ref('')
const tableLoading = ref(false)
const _searchData = () => {
    
    pageNum.value = 1
    _loadCustomers()
}

const _loadCustomers = () => {
    tableLoading.value = true
    CBSDK.Customer.query({
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        searchContent: searchContent.value
    }).then(res => {
        customers.value = res.data
        total.value = res.total
        tableLoading.value = false
    }).catch(err => {
        tableLoading.value = false
    })
}

const handleCurrentChange = (val) => {
    pageNum.value = val
    _loadCustomers()
}

const _editCustomer = (obj) => {
    const customerStore = useCustomerStore()
    customerStore.set(obj)
    router.push({ path: '/customer/edit' })
}

const _deleteCustomer = (obj) => {
    ElMessageBox.confirm(
        '确定删除['+obj.name+']该客户信息吗?',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    ).then(res => {
        CBSDK.Customer.removeCustomer(obj._id).then(res => {
            ElNotification({
                title: '操作成功',
                message: '客户信息已删除',
                type: 'success'
            })
            _loadCustomers()
        }).catch(err => {
            ElNotification({
                title: '操作失败',
                message: '删除客户信息失败:' + err,
                type: 'error'
            })
        })
    }).catch(err => {

    })
}

const _addCustomer = () => {
    const customerStore = useCustomerStore()
    customerStore.clear()
    router.push({ path: '/customer/edit' })
}

onMounted(() => {
    _loadCustomers()
})
</script>

<style lang="scss" scoped>
.customer_view {
    width: 100%;

    .head {
        position: relative;

        h1 {
            text-align: left;
        }

        .add_btn {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .body {
        margin-top: 10px;
        margin-bottom: 10px;

        .search_panel {
            margin: 10px 0;
            padding: 10px;
            display: flex;
            justify-content: start;
        }
    }

    .footer {
        text-align: center;
    }
}
</style>